import isEqual from 'lodash/isEqual';
import React, { PropsWithChildren, useEffect, useMemo, } from 'react';
import { useAbilities, useAdminprofiles, useAuth, useIsPrint, useSuperadminProfiles, } from '@Hooks';
import { AlertError, isAdmin, } from '@Utils';
import {
  actionSetAbilities,
  actionSetIsFullscreen,
  actionSetTheme,
  IGlobalStore,
  useAppDispatch,
} from '@Store';
import { ISharedAdminprofile, SharedAbilities, SharedAbility, Nullable, ISharedSuperadminProfile, IPreviewUser, } from 'atlas-shared';
import { useTranslation, } from 'react-i18next';
import { patchUserStatus, } from '@Api';
import { SipContainer, } from 'SIP';
import { PasswordResetModal, } from '@Components/user/password-reset';
import { InactivityCheck, PopupModals, SwitchingProfile, } from '@Components';
import { KeyboardKeydown, } from './keyboard.keydown';

export const LoadDefaults = React.memo(({ children, }: PropsWithChildren<{}>) => {
  const abilities = useAbilities();
  const auth = useAuth();
  const adminprofiles = useAdminprofiles();
  const superadmin_profiles = useSuperadminProfiles();
  const dispatch = useAppDispatch();
  const { t, } = useTranslation();
  const hasVoice = useMemo(() => auth?.user.main && auth?.user.voice_license, [auth, ]);
  const isPrint = useIsPrint();

  /* eslint-disable react-hooks/exhaustive-deps*/
  /*
  useEffect(() => {
    hasVoice && voiceCallsOngoing().then(channels => {
      if (channels.length) {
        const [channel] = channels;

        dispatch(actionSetGlobalCall({
          voice_asterisk_id: channel.voice_asterisk_id,
          numbers: ['bridge'],
          requires_route: false,
          headers: [
            `X-CHANNELID: ${channel.name}`,
            `X-DESTINATION-UNIQUEID: ${channel.id}`
          ]
        }));
      }
    });
  }, [dispatch]);
   */

  useEffect(() => {
    if (auth.user_status && !auth.user_status.agentprofile_id && auth.user.__agent_organization_ids.length && auth.user.__agentprofiles.length)
      patchUserStatus(auth.user.id, { agentprofile_id: auth.user.__agentprofiles[0], });
  }, []);

  useEffect(() => {
    const exitHandler = () => {
      dispatch(actionSetIsFullscreen(!(!(document as any).webkitIsFullScreen && !(document as any).mozFullScreen && !(document as any).msFullscreenElement)));
    };

    if (document.addEventListener) {
      document.addEventListener('fullscreenchange', exitHandler, false);
      document.addEventListener('mozfullscreenchange', exitHandler, false);
      document.addEventListener('MSFullscreenChange', exitHandler, false);
      document.addEventListener('webkitfullscreenchange', exitHandler, false);
    }
  }, []);

  useEffect(() => {
    dispatch(actionSetTheme((new URLSearchParams(window.location.search).get('theme') as Nullable<IGlobalStore['theme']>) || localStorage.getItem('atlas-theme') || 'light'));
  }, [dispatch, ]);

  /* eslint-disable react-hooks/exhaustive-deps*/
  useEffect(() => {

    if (!auth || !((isAdmin(auth.user) && adminprofiles.loaded && superadmin_profiles.loaded) || !isAdmin(auth.user))) {
      return;
    }

    if (abilities && (abilities.getAbilities().length !== auth.user.__organizations.length || !isEqual(abilities.getAbilities().map(profile => profile.user_organization.organization_id), auth.user.__organizations.map(o => o.organization_id)))) {
      AlertError(t, {
        content: t('ADMIN_ORGANIZATIONS_CHANGED_RELOAD'),
      });
      return;
    }

    dispatch(actionSetAbilities(new SharedAbilities<SharedAbility<IPreviewUser>, IPreviewUser>(auth.user, auth.user.__organizations.map(user_organization => {
      let adminprofile: ISharedAdminprofile | ISharedSuperadminProfile = {} as ISharedAdminprofile;

      if (user_organization.adminprofile_id)
        adminprofile = adminprofiles.adminprofiles.find(adminprofile => adminprofile.id === user_organization.adminprofile_id) as ISharedAdminprofile;
      else if (user_organization.superadmin_profile_id)
        adminprofile = superadmin_profiles.superadmin_profiles.find(superadmin_profile => superadmin_profile.id === user_organization.superadmin_profile_id) as ISharedSuperadminProfile;

      return {
        user_organization,
        ability: new SharedAbility(adminprofile, auth.user, !!user_organization.superadmin_profile_id),
      };
    }))));

  }, [adminprofiles, superadmin_profiles, auth, dispatch, t, ]);

  return <KeyboardKeydown>
    <SwitchingProfile />
    {!isPrint && <SipContainer/>}
    <PasswordResetModal />
    <InactivityCheck />
    <PopupModals />
    {children}
  </KeyboardKeydown>;
});
