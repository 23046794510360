import React, { ReactElement, useCallback, useEffect, useMemo, useState, } from 'react';
import {
  IFormElement,
  IFormElementObject, IFormElements,
  IFormElementSelect, IFormElementTypeahead,
} from '../atlas-form-core';
import { Button, Select, SelectProps, } from 'antd';
import { useTranslation, } from 'react-i18next';
import debounce from 'lodash/debounce';
import { IOption, setPreventDefault, } from '@Utils';
import { useSort, } from '@Hooks/use.sort';
import { Icon, } from '@Components';
import { EditIcon, XIcon, } from '@Assets/icons';
import { IFormItemsInputProps, } from '@Modules/atlas-form-react/utils';
import FormSection from './form.section';
import { Undefinable, } from 'atlas-shared/dist';
import { TFunction, } from 'i18next';

interface IProps {
  value?: any;
  options?: Array<IOption>;
  className?: string;
  dropdownClassName?: string;
  onChange: (value: any, option?: any) => void;
  style?: IFormElement['element_style'];
  tag_style?: React.CSSProperties;
  onSearch?: IFormElementSelect['onSearch'];
  placeholder?: string;
  mode?: SelectProps<any>['mode'];
  maxTagCount?: SelectProps<any>['maxTagCount'];
  fixedDropdown?: true;
  optionRender?: (option: IOption, selected: any) => ReactElement;
  tagRender?: (props, options: Array<IOption>, t: TFunction) => ReactElement | string;
  sortable?: boolean;
  allowClear?: boolean;
  showSearch?: boolean;
  form_props?: {
    rest: IFormItemsInputProps;
    item: IFormElements;
    name: Array<string | number>;
  };
  disabled?: boolean;
  tagsOptionValidation?: IFormElementTypeahead['tagsOptionValidation'];
  keep_default_sorting?: IFormElementSelect['keep_default_sorting'];
}

function FormSelect (props: IProps) {

  const {
    value,
    onSearch,
    mode,
    maxTagCount,
    fixedDropdown,
    optionRender,
    onChange,
    form_props,
    tag_style,
    tagsOptionValidation,
    keep_default_sorting,
    sortable = true,
    allowClear = !!props.mode,
    showSearch = true,
    tagRender,
    disabled = false,
  } = props;

  const { t, } = useTranslation();
  const [options, setOptions, ] = useState<Array<IOption>>([]);
  const [editOption, setEditOption, ] = useState<Undefinable<number>>();
  const [id, ] = useState<string>(`select-options-${Math.floor(Math.random() * 10000000)}`);
  const { onDragStart, onDragEnd, onDragOver, canDrop, } = useSort({
    dropElement: <span className="drop-element">{t('DROP_HERE')}</span>,
    value,
    onChange,
  });

  useEffect(() => {
    if (value === undefined && !mode && props.options && props.options.length === 1)
      props.onChange(props.options[0].key, { label: props.options[0].title, value: props.options[0].key, });
  }, [mode, props, value, ]);

  useEffect(() => {
    if (!props.options)
      return;

    if (keep_default_sorting) {
      setOptions(props.options);
      return;
    }

    setOptions([...props.options, ].sort((a, b) => a.title < b.title ? -1 : 1));
  }, [props.options, ]);

  const groups = useMemo(() => {
    return options.filter(({ group, }) => !!group).map(({ group, group_order = 0, }) => ({ group, group_order, }));
  }, [options, ]);
  const hasGroups = groups.length;
  const groupedOptions = useMemo(() => {
    if (!hasGroups)
      return options;

    const groupedOptionsMap: Record<string, Array<IOption>> = {
      ...Object.fromEntries(groups.sort((a, b) => b.group_order - a.group_order).map(({ group, }) => ([group, [], ]))),
      others: [],
    };

    options.forEach(option => {
      (groupedOptionsMap[option.group || 'others'] || groupedOptionsMap.default).push(option);
    });

    if (!groupedOptionsMap.others.length)
      delete groupedOptionsMap.others;

    return Object.entries(groupedOptionsMap).map(([group, children, ]) => {
      return {
        label: t(`GROUP_${group.toUpperCase()}`),
        children,
      };
    });
  }, [options, hasGroups, ]);

  const status = useMemo(() => {
    return mode !== 'multiple' && mode !== 'tags' && value && !props.options?.some(option => !option.deleted && option.key === value) ? 'error' : undefined;
  }, [value, props.options, ]);

  const _optionRender = useCallback((option: IOption, index: number) => {
    const { key, title, display, disabled = false, deleted, description, } = option;
    const printTitle = title || t(key + '');

    return <Select.Option
      key={key as any}
      value={key as any}
      label={printTitle}
      data={option.entity}
      title={description}
      disabled={disabled || deleted}
      className={deleted ? 'ant-select-item-deleted' : ''}
    >
      {optionRender?.(option, value) || display || printTitle}
    </Select.Option>;
  }, []);

  /* eslint-disable react-hooks/exhaustive-deps*/
  const performSearch = useCallback(debounce((w: string) => {

    if (!onSearch || !w)
      return;

    onSearch(w).then((res) => {
      setOptions(res);
    });
  }, 500), [onSearch, ]);

  return (
    <div
      className={`select-wrapper${fixedDropdown ? ' fixed-dropdown' : ''}`}
      onDrop={setPreventDefault}
      onDragOver={setPreventDefault}
    >
      {
        <Select
          allowClear={allowClear}
          value={Array.isArray(value) ? value.map(v => v && typeof v === 'object' ? v.value : v) : value}
          status={status}
          onChange={options => {
            if (tagsOptionValidation)
              return props.onChange(options.filter(tagsOptionValidation));

            props.onChange(options);
          }}
          style={props.style}
          showSearch={showSearch}
          disabled={disabled}
          onSearch={(w) => {
            if (!onSearch)
              return;

            performSearch(w);
          }}
          filterOption={onSearch ? false : (w, option) => w ? ((option?.label || option?.title || '') as string).toLowerCase().includes(w.toLowerCase()) : true}
          placeholder={props.placeholder}
          mode={mode}
          maxTagCount={maxTagCount}
          listHeight={250}
          listItemHeight={10}
          getPopupContainer={fixedDropdown ? () => document.querySelector(`#${id}`) as HTMLElement : undefined}
          open={fixedDropdown}
          className={props.className}
          dropdownClassName={props.dropdownClassName}
          dropdownMatchSelectWidth={false}
          tagRender={(props) => {
            const index = value.findIndex(v => (v?.value || v) === props.value);
            const item = value[index];
            const key = item?.alias || item?.key || item?.title || item?.stats_custom_metric_id || item;
            const option = options.find(o => o.key === key);
            const title = t(option?.title || key);
            const display = option?.display || title;
            const color = item?.color;
            const deleted = option?.deleted || (mode !== 'tags' && !option);
            // const deleted = option?.deleted || !options.some(o => o.key === props.value);

            return <div className='ant-select-selection-item-wrapper'>
              {canDrop(index, true)}
              <div
                //title={typeof props.label === 'string' ? props.label + '' : undefined}
                title={title + (option?.description ? (option?.description) : '') + (deleted ? `\n${t('DELETED_ITEM_REMOVE')}` : '')}
                onDragOver={() => onDragOver(index)}
                onMouseDown={e => e.stopPropagation()}
                draggable={!disabled && sortable}
                onDragStart={e => onDragStart(e, index)}
                onDragEnd={onDragEnd}
                className={`ant-select-selection-item${!disabled && sortable ? ' draggable' : ''}${deleted ? ' deleted' : ''}`}
                style={{ color, background: item?.bg_color || item?.box_color, }}
              >
                {!disabled && form_props && typeof item === 'object' && <Icon icon={EditIcon} tooltip={{ title: t('EDIT_ITEM'), }} className='ant-select-selection-item-edit' iconProps={{ size: 12, style: { color, }, }}
                  onClick={() => {
                    setEditOption(index);
                  }}/>}
                <span className='ant-select-selection-item-content' style={tag_style || {}}>{tagRender ? tagRender(item, options, t) : display}</span>
                {!disabled && <Icon icon={XIcon} iconProps={{ style: { color, }, }} className='ant-select-selection-item-remove' onClick={_ => props.onClose()} />}
              </div>
              {canDrop(index, false)}
            </div>;
          }}
        >

          {hasGroups ? groupedOptions.map(group => {
            return <Select.OptGroup label={group.label}>
              {group.children.map(_optionRender)}
            </Select.OptGroup>;
          }) : options.map(_optionRender)}
        </Select>
      }
      {fixedDropdown && <div id={id} className='select-options'></div>}
      {form_props && editOption !== undefined && <div className='form-typeahead-edit-popup'>
        <div className='background' onClick={() => setEditOption(undefined)}></div>
        <div className='inner'>
          <FormSection
            {...form_props.rest}
            form={form_props.item?.items?.[editOption] as IFormElementObject}
            name={[editOption, ]}
            path={[...form_props.rest.path, ...form_props.name, ]}
          />
          <Button type='primary' onClick={() => setEditOption(undefined)}>{t('DONE')}</Button>
        </div>
      </div>}
      {mode && ['tags', 'multiple', ].includes(mode) && Array.isArray(value) && !value.length && !!options.length && <div onClick={() => onChange(options.filter(option => !option.deleted).map(option => option.key))} className='select-all'>{t('SELECT_ALL')}</div>}
    </div>
  );
}

export default React.memo(FormSelect);
