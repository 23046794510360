import {
  EnumValues,
  findMetricByAliasOrId,
  IMetric,
  IPreviewStatsReport,
  IPreviewStatsReportPreviewWidget,
  IPreviewStatsReportWidget,
  IPreviewStatsWidget,
  Nullable,
  OrganizationDashboardReportingTypeEnum,
  StatsWidgetPeriodEnum,
  StatsWidgetTypeEnum,
  Undefinable,
} from 'atlas-shared/dist';
import { StatsWidgetTypesRechartsMap, } from './widget-type/recharts/map';
import { AppSpinner, Icon, IconText, } from '@Components';
import './stats.widget.scss';
import './stats.widget.data.scss';
import { StatsWidgetSplitView, } from './stats_widget.split.view';
import { TFunction, } from 'i18next';
import {
  ChevronDownIcon,
  ChevronUpIcon,
  ClockIcon,
  CopyIcon,
  CornerUpRight,
  EditIcon,
  EyeIcon,
  MoveIcon,
  RefreshIcon,
  TrashIcon,
} from '@Assets';
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  useClients,
  useDispositions,
  useFormWebsites,
  useMailAccounts,
  useOrganizations,
  usePauses,
  useQueues,
  useStatsCustomMetrics,
  useStatsWidgets,
  useUsers,
  useUserStatuses,
  useVoiceRoutes,
} from '@Hooks';
import {
  deleteStatsReportPreviewWidget,
  deleteStatsReportWidget,
  fetchStatsWidgetData,
  patchStatsReportWidget,
  setStatsReportActive,
  transferStatsWidgetToOwner,
} from '@Api';
import { IAuth, } from '@Store';
import {
  AlertError,
  Confirm,
  IMetricDataDicts,
  mergeClientData,
  stringToAlias,
  TClientDataMetrics,
  timeSince,
  TypeModal,
} from '@Utils';
import { IWithStatsReportProps, } from '@Hocs/with.stats_report';
import moment from 'moment';
import { FormSelect, } from 'atlas-form';

interface IButton {
  text: string;
  icon: any;
  onClick?: () => void;
  classNames?: Array<string>;
  key: string;
}

interface IProps {
  t: TFunction;
  auth: IAuth;
  report: IPreviewStatsReport;
  stats_report_widget: IPreviewStatsReportWidget | IPreviewStatsReportPreviewWidget;
  widgets: Array<IPreviewStatsReportWidget | IPreviewStatsReportPreviewWidget>;
  buttons?: Array<IButton | false>;
  setWidgetForm?: Dispatch<SetStateAction<number>>;
  setWidgetClone?: Dispatch<SetStateAction<number>>;
  is_preview: boolean;
  index: number;
  screenshotWidgetBtn?: IWithStatsReportProps['screenshotWidgetBtn'];
  demo_data?: IPreviewStatsWidget;
  is_demo?: boolean;
  is_print: boolean;
  width: number;
  height?: number;
  is_first?: boolean;
  is_last?: boolean;
}

const dateTimeFormat = 'YYYY-MM-DD HH:mm';

export const StatsWidgetView = ({
  t,
  report,
  widgets,
  index,
  is_preview,
  is_print,
  stats_report_widget,
  auth,
  setWidgetForm,
  setWidgetClone,
  buttons,
  screenshotWidgetBtn,
  demo_data,
  is_demo,
  width,
  height: _height,
  is_first = false,
  is_last = false,
}: IProps) => {
  const stats_widgets = useStatsWidgets();
  const user_statuses = useUserStatuses();
  const stats_custom_metrics = useStatsCustomMetrics();
  const organizations = useOrganizations();
  const users = useUsers();
  const pauses = usePauses();
  const queues = useQueues();
  const clients = useClients();
  const dispositions = useDispositions();
  const mail_accounts = useMailAccounts();
  const voice_routes = useVoiceRoutes();
  const form_websites = useFormWebsites();

  const [loading, setLoading, ] = useState<boolean>(false);

  const fetching = useRef(false);
  const [statsWidget, setStatsWidget, ] = useState<IPreviewStatsWidget>();
  const [statsWidgetData, setStatsWidgetData, ] = useState<Pick<IPreviewStatsWidget, 'last_data_at' | 'data'>>({});
  const metric_data_dicts: IMetricDataDicts = { organizations, users, pauses, queues, clients, dispositions, mail_accounts, voice_routes, form_websites, };
  const has_trend = !!statsWidget?.settings?.trend;
  const [lastDataAt, setLastDataAt, ] = useState<Nullable<string>>(null);
  const [lastDataTimeSince, setLastDataTimeSince, ] = useState<Nullable<string>>(null);
  const [data, setData, ] = useState<IPreviewStatsWidget['data']>();
  const [hasData, setHasData, ] = useState<boolean>(false);
  const [customPeriod, setCustomPeriod, ] = useState<Undefinable<StatsWidgetPeriodEnum>>();
  const [pickCustomPeriod, setPickCustomPeriod, ] = useState<boolean>();
  const organization = useMemo(() => statsWidget ? organizations.dict[statsWidget.organization_id] : null, [statsWidget, organizations, ]);
  const isEntry = useMemo(() => organization ? organization[(window.location.pathname as string).includes('dashboard') ? 'reduced_dashboard_license_type' : 'reduced_reporting_license_type'] === OrganizationDashboardReportingTypeEnum.Entry : false, [organization, ]);

  const innerWidth = width - 16;
  const height = _height || (report.is_dashboard ? width * .65 : 550);
  const innerHeight = height ? height - 16 - 23 - 20 - 10 : innerWidth * .65;

  const setLastData = useCallback(() => {
    const m = statsWidget?.last_data_at ? moment(statsWidget.last_data_at) : undefined;

    setLastDataAt(m?.format(dateTimeFormat) || null);
    setLastDataTimeSince(m ? timeSince(t, m.toDate(), true) : null);
  }, [statsWidget?.last_data_at, ]);

  useEffect(() => {
    const interval = setInterval(() => {
      setLastData();
    }, 60_000);

    setLastData();
    return () => {
      clearInterval(interval);
    };
  }, [statsWidget?.last_data_at, ]);

  const setPosition = (stats_report_widget: IPreviewStatsReportWidget, position: IPreviewStatsReportWidget['settings']['position']) => {
    patchStatsReportWidget(stats_report_widget.id, { settings: { position, }, });
  };

  const movePosition = useCallback((index: number, up: boolean) => {
    const current = widgets[index] as IPreviewStatsReportWidget;
    const other = widgets[up ? index - 1 : index + 1] as IPreviewStatsReportWidget;

    if (other?.settings?.position === undefined || current?.settings?.position === undefined)
      return;

    setPosition(current as IPreviewStatsReportWidget, other.settings.position);

    if (other)
      setPosition(other as IPreviewStatsReportWidget, current.settings.position);
  }, [widgets, ]);

  const merged_buttons = useMemo(() => {

    const isReportOwner = auth.user.id && auth.user.id === report.user_id;
    const isOwner = auth.user.id && auth.user.id === statsWidget?.user_id;
    const isPreviewOwner = auth.user.id && auth.user.id === (stats_report_widget as IPreviewStatsReportPreviewWidget)?.user_id;

    return [
      !isEntry && statsWidget && statsWidget.settings.period.type !== StatsWidgetPeriodEnum.LIVE_TODAY && !report.is_dashboard && statsWidget.user_id !== auth.user.id && {
        key: 'custom_settings',
        text: t('PREVIEW_PERIOD'),
        icon: EyeIcon,
        onClick: () => {
          setPickCustomPeriod(!pickCustomPeriod);
        },
      },
      !isEntry && setWidgetForm && isOwner && {
        key: 'edit',
        text: t('EDIT'),
        icon: EditIcon,
        onClick: () => setWidgetForm(statsWidget.id),
      },
      !isEntry && setWidgetClone && isReportOwner && statsWidget && {
        key: 'clone',
        text: t('CLONE'),
        icon: CopyIcon,
        onClick: () => setWidgetClone(statsWidget.id),
      },
      !is_preview && stats_report_widget && isOwner && {
        key: 'delete',
        text: t('DELETE'),
        icon: TrashIcon,
        onClick: () => {
          Confirm(t, {
            onOk: (modal: TypeModal) => {
              return deleteStatsReportWidget(stats_report_widget.id)
                .then(deleted => {
                  modal.destroy();

                  if (report.is_dashboard)
                    return setStatsReportActive(report.id, null, false);
                });
            },
            title: t('DELETE_WIDGET'),
            content: t('DELETE_WIDGET_DESCRIPTION'),
          });
        },
      },
      stats_report_widget && isOwner && statsWidget.user_id !== report.user_id && {
        key: 'transfer_widget',
        text: t('TRANSFER_TO_REPORT_OWNER'),
        icon: CornerUpRight,
        onClick: () => transferStatsWidgetToOwner(report.id, statsWidget.id)
          .catch(() => AlertError(t, {
            title: t('SOMETHING_WENT_WRONG'),
          })),
      },
      is_preview && stats_report_widget && isPreviewOwner && {
        key: 'preview_delete',
        text: t('DELETE'),
        icon: TrashIcon,
        onClick: () => deleteStatsReportPreviewWidget(stats_report_widget.id)
          .then(() => setStatsReportActive(report.id, null, true)),
      },
      statsWidget && ((report.is_dashboard && isReportOwner) || (is_preview && isPreviewOwner)) && {
        key: 'DRAG_n_MOVE',
        text: t('DRAG_n_MOVE'),
        icon: MoveIcon,
        classNames: ['draggable-handle', ],
      },
      statsWidget && statsWidget.settings.period.type !== StatsWidgetPeriodEnum.LIVE_TODAY && {
        key: 'reload',
        text: t('RELOAD_DATA'),
        icon: RefreshIcon,
        onClick: () => fetchStaticData(statsWidget.id),
      },
      !is_preview && !is_last && !report.is_dashboard && {
        key: 'move-down',
        text: t('DOWN'),
        icon: ChevronDownIcon,
        onClick: () => movePosition(index, false),
      },
      !is_preview && !is_first && !report.is_dashboard && {
        key: 'move-up',
        text: t('UP'),
        icon: ChevronUpIcon,
        onClick: () => movePosition(index, true),
      },
      statsWidget && stats_report_widget && screenshotWidgetBtn?.('png', stats_report_widget.id, stringToAlias(statsWidget.title)),
      statsWidget && stats_report_widget && screenshotWidgetBtn?.('pdf', stats_report_widget.id, stringToAlias(statsWidget.title)),
      statsWidget?.type && [StatsWidgetTypeEnum.Table, StatsWidgetTypeEnum.Grid, ].includes(statsWidget.type) && stats_report_widget && statsWidget.data?.g !== 'split' && screenshotWidgetBtn?.('csv', stats_report_widget.id, stringToAlias(statsWidget.title)),
      ...(buttons || []),
    ].filter(Boolean) as Array<IButton>;
  }, [auth, statsWidget, buttons, organization, index, ]);

  useEffect(() => {
    if (stats_report_widget) {
      const stats_widget = stats_widgets.dict[stats_report_widget.stats_widget_id];

      if (stats_widget)
        setStatsWidget({
          ...stats_widget,
          ...(!stats_widget.is_live ? statsWidgetData : {}),
        });
    }
  }, [statsWidgetData, stats_widgets.dict[stats_report_widget.stats_widget_id], ]);

  useEffect(() => {
    if (
      !is_demo
      && !fetching.current
      && statsWidget?.id && statsWidget?.id > 0
      && (
        (!statsWidgetData.last_data_at && is_print)
      || (
        statsWidget.settings.period.type !== StatsWidgetPeriodEnum.LIVE_TODAY
        && (
          // settings updated
          JSON.stringify(stats_report_widget && stats_widgets.dict[stats_report_widget.stats_widget_id]?.settings || {}) !== JSON.stringify(statsWidget.settings || {})
          // just loaded
          || !statsWidgetData.last_data_at
        )
      )
      )
    )
      fetchStaticData(statsWidget?.id || (stats_report_widget && stats_widgets.dict[stats_report_widget.stats_widget_id]?.id) || null, customPeriod);
  }, [statsWidget, customPeriod, stats_widgets.dict[stats_report_widget.stats_widget_id], ]);

  useEffect(() => {
    if (demo_data) {
      setStatsWidget(demo_data);
      setData(demo_data.data);
    }
  }, [demo_data, ]);

  useEffect(() => {
    setHasData(JSON.stringify(data || {}) !== '{}');
  }, [data, ]);

  const fetchStaticData = useCallback((id: Nullable<IPreviewStatsWidget['id']>, customPeriod?: StatsWidgetPeriodEnum) => {
    if (fetching.current)
      return;

    if (id) {
      setLoading(true);
      fetchStatsWidgetData(id, customPeriod)
        .then(data => {
          setLoading(false);
          fetching.current = false;
          setStatsWidgetData({
            data,
            last_data_at: new Date().toISOString(),
          });
        });

      fetching.current = true;
    }
  }, []);

  const metrics = useMemo(() => {
    if (!statsWidget)
      return [];

    return [...(statsWidget.settings.metrics || []), ...(statsWidget.settings.nested_metrics || []).flatMap(metrics => metrics.metrics), ].map(m => findMetricByAliasOrId((m.alias || m.stats_custom_metric_id) as any, stats_custom_metrics.stats_custom_metrics) as IMetric);
  }, [statsWidget?.settings, stats_custom_metrics, ]);

  const client_data_metrics = useMemo(() => {
    const client_data_metrics: TClientDataMetrics = [];

    metrics?.forEach((metric, index) => {
      if ((metric as IMetric)?.client_data)
        client_data_metrics.push([metric as IMetric, index, ]);
    });

    return client_data_metrics;
  }, [metrics, ]);
  const has_client_data = useMemo(() => !!client_data_metrics.length, [client_data_metrics, ]);

  useEffect(() => {
    const data = statsWidgetData.data || statsWidget?.data;

    if (has_client_data && statsWidget && data)
      return setData(mergeClientData(t, statsWidget, data, user_statuses, client_data_metrics));

    setData(data);
  }, [statsWidget?.data, statsWidgetData, ]);

  useEffect(() => {
    if (!has_client_data)
      return;

    const data = statsWidgetData.data || statsWidget?.data;

    if (has_client_data && statsWidget && data)
      return setData(mergeClientData(t, statsWidget, data, user_statuses, client_data_metrics));
  }, [user_statuses, has_client_data, ]);

  // useEffect(() => {
  //   const ts = setInterval(() => {
  //     let root: any = clone(statsWidget?.data || {});
  //
  //     if (root?.r?.[0]?.c?.r?.[0]) {
  //       console.log('statsWidget.data', statsWidget?.id);
  //       root.r[0].c.r[0].d[root.r[0].c.r[0].d.length - 1] = Math.round(Math.random() * 1000);
  //     }
  //
  //     setData(root);
  //   }, 1000);
  //
  //   return () => clearInterval(ts);
  // }, [statsWidget]);

  if (!statsWidget?.id)
    return <div className={'stats-widget-wrapper not-found'}>
      <h2>{t('WIDGET_NOT_FOUND')}</h2>
      <div className='buttons'>
        {merged_buttons.map(({ icon, text, key, onClick, }) => <IconText
          key={key}
          icon={icon}
          text={text}
          onClick={onClick}
        /> )}
      </div>
      <p>{t('WIDGET_NOT_FOUND_DESCRIPTION')}</p>

    </div>;

  const Component = StatsWidgetTypesRechartsMap[statsWidget.type];

  return <div className='stats-widget-wrapper' id={`stats-widget-${statsWidget.id || 'demo'}`}>
    <h3 className='title'>
      {statsWidget.title}
      <span style={customPeriod ? { textDecoration: 'line-through', } : {}}>{statsWidget.settings.period.type === StatsWidgetPeriodEnum.CUSTOM ? `${moment(statsWidget.settings.period.from).format('YYYY-MM-DD HH:00')}-${moment(statsWidget.settings.period.to).format('YYYY-MM-DD HH:00')}` : t(`STATS_WIDGET_PERIOD_${statsWidget.settings.period.type.toUpperCase()}`)}</span>
      {customPeriod && <span>{t(`STATS_WIDGET_PERIOD_${customPeriod.toUpperCase()}`)}</span>}
      {statsWidget.settings.time_interval && <span>{statsWidget.settings.time_interval.from}-{statsWidget.settings.time_interval.to}</span>}
    </h3>
    {pickCustomPeriod && <div className='pick-custom-period'>
      <table>
        <tr>
          <th>{t('PERIOD')}</th>
          <td>
            <FormSelect
              value={customPeriod || statsWidget?.settings.period.type}
              options={
                EnumValues(StatsWidgetPeriodEnum)
                  .filter(k => ![StatsWidgetPeriodEnum.CUSTOM, StatsWidgetPeriodEnum.LIVE_TODAY, ].includes(k))
                  .map(k => ({
                    key: k,
                    title: t(`STATS_WIDGET_PERIOD_${k}`),
                  }))
              }
              onChange={period => {
                setCustomPeriod(period);
                fetchStaticData(statsWidget.id, period);
                setPickCustomPeriod(false);
              }}
            />
          </td>
        </tr>
      </table>

    </div>}
    {loading && <div className='loading'>
      <AppSpinner />
    </div>}
    {!is_demo && !loading && !hasData && <div className='no-data'>{t('WIDGET_HAS_NO_DATA')}</div>}
    {(organization && (metrics && data?.g && data?.g !== 'split') || statsWidget.type === StatsWidgetTypeEnum.Timeline) && Component && <Component
      t={t}
      stats_report={report}
      stats_widget={statsWidget}
      data={data}
      width={width}
      height={height}
      innerWidth={innerWidth}
      innerHeight={innerHeight}
      metrics={metrics}
      stats_custom_metrics={stats_custom_metrics}
      metric_data_dicts={metric_data_dicts}
      has_trend={has_trend}
      organization={organization}
    />}
    {organization && metrics && data && statsWidget.data?.g === 'split' && <StatsWidgetSplitView
      t={t}
      stats_widget={statsWidget}
      stats_report={report}
      data={data}
      metrics={metrics}
      width={width}
      height={height}
      innerWidth={innerWidth}
      innerHeight={innerHeight}
      stats_custom_metrics={stats_custom_metrics}
      metric_data_dicts={metric_data_dicts}
      has_trend={has_trend}
      organization={organization}
    />}
    {!is_demo && <div className='hover-top'>
      {statsWidget.last_data_at && <div className='infos'>
        <div className='info last-data-at'><IconText text={lastDataTimeSince} icon={ClockIcon} tooltip={{
          title: <span>{t('LAST_DATA_AT')}: {lastDataAt}</span>,
        }}/></div>
      </div>}
      <div className='buttons'>{merged_buttons.map(({ icon, text, onClick, key, classNames = [], }) => <div
        key={key}
        className={['button', ...classNames, ].join(' ')}>
        <Icon
          icon={icon}
          tooltip={{
            title: text,
            placement: 'bottom',
          }}
          onClick={onClick}
        />
      </div>)}
      </div>
    </div>}
  </div>;
};
