import { NavigateFunction, } from 'react-router';
import {
  FormElementTypes,
  FormLayoutTypes,
  IForm,
  IFormOptions,
} from 'atlas-form';
import { IAuth, IAbilities, } from '@Store';
import { CrudEnum, PermissionEnum, ISharedCreateTag, ISharedUpdateTag, ISharedTag, IPreviewOrganization, } from 'atlas-shared';
import { TFunction, } from 'i18next';
import { nestedArray, entitiesToOptions, onFormSaved, } from '@Utils';

export interface ITagFormOptionsProps {
  t: TFunction;
  is_new: boolean;
  abilities: IAbilities;
  organizations: Array<IPreviewOrganization>;
}

export const TagFormOptions = (tag: ISharedCreateTag | ISharedTag, props: ITagFormOptionsProps, t: TFunction): IFormOptions => ({
  organization_id: entitiesToOptions<IPreviewOrganization>(nestedArray<any>(props.organizations.filter(organization => props.abilities.can(organization.id, CrudEnum.Insert, PermissionEnum.Tag, {})), 'organization_id', false), 'organization'),
  
});

export const TagComponents: IForm['components'] = {};
export const TagAdditionalParams: (auth: IAuth, props: ITagFormOptionsProps) => IForm['additionalParams'] = (auth: IAuth, props: ITagFormOptionsProps) => ({
});

export const TagEditForm = (tag: ISharedTag, props: ITagFormOptionsProps, t: TFunction, label?: string): IForm => ({
  id: `tag_edit_${tag.id}`,
  label: label,
  ui_layout: FormLayoutTypes.HORIZONTAL,
  elements: [
    { ui_type: FormElementTypes.PREVIEW, preview_value: props.organizations.find(it => it.id === tag.organization_id)?.title, path: 'organization_id', shouldUpdate: () => false, },
    { ref: 'title', },
    { ref: 'global', },
    { ref: 'description', },
  ],
});

export const TagAddForm = (tag: ISharedCreateTag, props: ITagFormOptionsProps, t: TFunction, label?: string): IForm => ({
  id: 'tag_add',
  label: label,
  ui_layout: FormLayoutTypes.HORIZONTAL,
  elements: [
    { ref: 'organization_id', ui_type: FormElementTypes.TREE, },
    { ref: 'title', },
    { ref: 'global', },
    { ref: 'description', },
  ],
});

export const TagAddInitialValues: ISharedCreateTag = {} as ISharedCreateTag;

export const TagEditInitialValues = (tag: ISharedTag): ISharedUpdateTag => ({
  title: tag.title,
  global: tag.global,
  description: tag.description,
});

export const onTagFormSaved = (t: TFunction, action: 'edit' | 'add', tag: ISharedTag, navigate: NavigateFunction): void => {
  navigate(onFormSaved('tags'));
};
