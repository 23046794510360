import { RestRequest, } from '@Utils/rest';
import { ISharedDraft, ISharedMailMessage, ISharedCannedAnswer, } from 'atlas-shared';
import { download, } from '@Utils';

export * from './abstract/draft.api.abstract';

export const sendDraft = (draft_id: ISharedDraft['id']) => {
  return RestRequest.post<any, boolean>(`draft/${draft_id}/send`, {});
};

export const downloadDraftAttachment = (draft_id: ISharedDraft['id'], index: number, filename: string, onError?: (error) => void) => {
  download(`draft/${draft_id}/attachment/${index}`, filename, onError);
};

export const deleteDraftAttachment = (draft_id: ISharedDraft['id'], index: number) => {
  return RestRequest.delete<any>(`draft/${draft_id}/attachment/${index}`);
};

export const fetchGlobalDrafts = (organization_id: ISharedDraft['organization_id']) => {
  return RestRequest.get<Array<ISharedDraft>>(`draft/${organization_id}/global`, {});
};

export const copyMailMessageAttachments = (draft_id: ISharedDraft['id'], mail_message_id: ISharedMailMessage['id']) => {
  return RestRequest.get<boolean>(`draft/${draft_id}/copy-attachments/mail_message/${mail_message_id}`);
};

export const copyCannedAnswerAttachments = (draft_id: ISharedDraft['id'], canned_answer_id: ISharedCannedAnswer['id']) => {
  return RestRequest.get<boolean>(`draft/${draft_id}/copy-attachments/canned_answer/${canned_answer_id}`);
};

export const uploadAttachment = (draft_id: ISharedDraft['id'], file) => {
  return RestRequest.upload(`draft/${draft_id}/attachment`, file, 'attachment');
};

export const getAttachmentToken = (url: string) => {
  return RestRequest.get<{token: string}>(`${url}/token`);
};
