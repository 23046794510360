import React, { PropsWithChildren, useCallback, useEffect, useRef, useState, } from 'react';
import { useAuth, useIsInactive, useIsPrint, } from '@Hooks';
import { io, ManagerOptions, SocketOptions, } from 'socket.io-client';
import {
  IPreviewAdminprofile,
  IPreviewAgentprofile,
  IPreviewCannedAnswer,
  IPreviewConversationFilter,
  IPreviewConversationWatcher,
  IPreviewDisposition,
  IPreviewDraft,
  IPreviewField,
  IPreviewForm,
  IPreviewList,
  IPreviewMailAccount,
  IPreviewJourney,
  IPreviewQueue,
  IPreviewNotificationUser,
  IPreviewOperationalHours,
  IPreviewOrganization,
  IPreviewPause,
  IPreviewSlaPolicy,
  IPreviewTab,
  IPreviewTeam,
  IPreviewTimeInterval,
  IPreviewUser,
  IPreviewUserStatus,
  IPreviewInternalNote,
  IPreviewMailMessage,
  IPreviewRecentlySearched,
  IPreviewRecentlyViewedConversation,
  IPreviewJourneyDraft,
  IPreviewTag,
  IPreviewSignature,
  IPreviewVoiceAsterisk,
  IPreviewVoiceRoute,
  IPreviewVoiceTrunk,
  IPreviewSound,
  IPreviewJourneyVariable,
  IPreviewVoiceCall,
  IPreviewVoiceRecording,
  IPreviewVoiceInternalCall,
  IPreviewVoiceChanspy,
  IPreviewVoiceVoicemailMessage,
  IPreviewVoiceVoicemail,
  IPreviewStatsReport,
  IPreviewStatsWidget,
  IStatsWidgetDataPayload,
  IPreviewStatsReportWidget,
  ChannelEnum,
  IPreviewStatsReportPreviewWidget,
  IPreviewVoiceCallBridge,
  IPreviewStatsCustomMetric,
  IPreviewVoiceAsteriskDowngrade,
  IPreviewTariff,
  IPreviewTariffParent,
  IPreviewSuperadminProfile,
  IPreviewInvoice,
  IPreviewPrepaidCredit,
  IPreviewPhonenumberMonthlyCost,
  IPreviewPhonenumberMonthlyCostParent,
  CONTAINER_IS_TERMINATING,
  ADD_SUBSCRIPTIONS,
  Nullable,
  IPreviewPhonenumberSetupCost,
  IPreviewPhonenumberSetupCostParent,
  IPreviewPhonenumberPurchase,
  IPreviewRelease,
  IPreviewApiKey,
  IPreviewClient,
  IPreviewFormWebsite,
  FORCE_USER_LOGOUT,
  IPreviewSkill,
  IPreviewWallboard,
  IPreviewJourneyTrigger,
  IPreviewGdpr,
  JourneyActionEnum,
} from 'atlas-shared';
import { AlertError, BrowserNotification, isAdmin, isAgent, signout, } from '@Utils';
import {
  actionAddAdminprofile,
  actionAddAgentprofile,
  actionAddCannedAnswer,
  actionAddConversation,
  actionAddConversationFilter,
  actionAddDisposition,
  actionAddField,
  actionAddForm,
  actionAddList,
  actionAddMailAccount,
  actionAddJourney,
  actionAddQueue,
  actionAddNotificationUser,
  actionAddOperationalHours,
  actionAddOrganization,
  actionAddPause,
  actionAddSlaPolicy,
  actionAddTab,
  actionAddTeam,
  actionAddTimeInterval,
  actionAddUser,
  actionAddUserStatus,
  actionRemoveConversationFilter,
  actionRemoveTab,
  actionRemoveUserStatus,
  actionUpdateAdminprofile,
  actionUpdateAgentprofile,
  actionUpdateCannedAnswer,
  actionUpdateConversation,
  actionUpdateConversationFilter,
  actionUpdateDisposition,
  actionUpdateField,
  actionUpdateForm,
  actionUpdateList,
  actionUpdateMailAccount,
  actionUpdateJourney,
  actionUpdateQueue,
  actionUpdateNotificationUser,
  actionUpdateOperationalHours,
  actionUpdateOrganization,
  actionUpdatePause,
  actionUpdateSlaPolicy,
  actionUpdateTab,
  actionUpdateTeam,
  actionUpdateTimeInterval,
  actionUpdateUser,
  actionUpdateUserStatus,
  authSetUserStatusState,
  actionFetchAdminprofiles,
  actionFetchAgentprofiles,
  actionFetchConversationFilters,
  actionFetchDispositions,
  actionFetchMailAccounts,
  actionFetchQueues,
  actionFetchOrganizations,
  actionFetchPauses,
  actionFetchTabs,
  actionFetchUsers,
  actionAddConversationDraft,
  actionAddConversationMessage,
  actionUpdateConversationMessage,
  actionRemoveConversationDraft,
  actionClearAllRecentlySearched,
  actionClearAllRecentlyViewed,
  actionAddRecentlySearched,
  actionUpdateRecentlySearched,
  actionRemoveRecentlySearched,
  actionAddRecentlyViewedConversation,
  actionUpdateRecentlyViewedConversation,
  actionRemoveRecentlyViewedConversation,
  actionAddJourneyDraft,
  actionUpdateJourneyDraft,
  actionRemoveJourneyDraft,
  authSetUserState,
  actionUpdateConversationDraft,
  actionAddTag,
  actionUpdateTag,
  actionAddSignature,
  actionUpdateSignature,
  conversationsUpdateFullState,
  actionAddConversationConversationWatcher,
  actionRemoveConversationConversationWatcher,
  actionAddFormWebsite,
  actionUpdateFormWebsite,
  actionAddVoiceAsterisk,
  actionUpdateVoiceAsterisk,
  actionAddVoiceTrunk,
  actionUpdateVoiceTrunk,
  actionAddVoiceRoute,
  actionUpdateVoiceRoute,
  actionAddSound,
  actionUpdateSound,
  actionAddJourneyVariable,
  actionUpdateJourneyVariable,
  actionAddConversationVoiceRecording,
  actionUpdateConversationVoiceRecording,
  actionAddVoiceInternalCall,
  actionUpdateVoiceInternalCall,
  actionAddVoiceCall,
  actionUpdateVoiceCall,
  actionAddVoiceChanspy,
  actionUpdateVoiceChanspy,
  actionAddConversationVoiceVoicemailMessage,
  actionUpdateConversationVoiceVoicemailMessage,
  actionAddVoiceVoicemail,
  actionUpdateVoiceVoicemail,
  useAppDispatch,
  actionAddStatsReport,
  actionUpdateStatsReport,
  actionRemoveStatsReport,
  actionAddStatsWidget,
  actionUpdateStatsWidget,
  actionRemoveStatsWidget,
  actionUpdateStatsWidgetData,
  actionAddStatsReportWidget,
  actionRemoveStatsReportWidget,
  actionUpdateStatsReportWidget,
  actionSetHandlingConversation,
  actionSetFocusedConversation,
  actionAddStatsReportPreviewWidget,
  actionUpdateStatsReportPreviewWidget,
  actionRemoveStatsReportPreviewWidget,
  IReducerStore,
  actionSetSocketDisconnected,
  actionAddConversationVoiceCallBridge,
  actionUpdateConversationVoiceCallBridge,
  actionAddStatsCustomMetric,
  actionUpdateStatsCustomMetric,
  actionAddVoiceAsteriskDowngrade,
  actionUpdateVoiceAsteriskDowngrade,
  actionAddTariff,
  actionUpdateTariff,
  actionAddTariffParent,
  actionUpdateTariffParent,
  actionAddSuperadminProfile,
  actionUpdateSuperadminProfile,
  actionAddInvoice,
  actionUpdateInvoice,
  actionRemoveInvoice,
  actionAddPrepaidCredit,
  actionUpdatePrepaidCredit,
  actionRemovePrepaidCredit,
  actionAddPhonenumberMonthlyCost,
  actionUpdatePhonenumberMonthlyCost,
  actionAddPhonenumberMonthlyCostParent,
  actionUpdatePhonenumberMonthlyCostParent,
  actionAddPhonenumberSetupCost,
  actionUpdatePhonenumberSetupCost,
  actionAddPhonenumberSetupCostParent,
  actionUpdatePhonenumberSetupCostParent,
  actionAddPhonenumberPurchase,
  actionUpdatePhonenumberPurchase,
  actionAddRelease,
  actionUpdateRelease,
  actionAddApiKey,
  actionUpdateApiKey,
  actionRemoveApiKey,
  actionAddClient,
  actionUpdateClient,
  actionAddSkill,
  actionUpdateSkill,
  actionAddWallboard,
  actionUpdateWallboard,
  actionFetchStatsWidgets,
  actionUpdateJourneyTrigger,
  actionAddJourneyTrigger,
  actionRemoveJourneyTrigger,
  actionAddGdpr,
  actionUpdateGdpr,
  actionRemoveGdpr,
} from '@Store';
import { AppSpinner, } from '@Components';
import { useTranslation, } from 'react-i18next';
import { useSelector, } from 'react-redux';
import { Socket, } from 'socket.io-client/build/esm/socket';

let connection: Socket;

export const SocketContainer = React.memo(({ children, }: PropsWithChildren<{}>) => {

  const dispatch = useAppDispatch();
  const auth = useAuth();
  const [loaded, setLoaded, ] = useState(false);
  const [loading, setLoading, ] = useState(false);
  const { t, } = useTranslation();
  const hasException = useRef<boolean>(false);
  const isPrint = useIsPrint();
  const selectors: Record<string, {loaded: boolean}> = {
    adminprofiles: useSelector((store: IReducerStore) => store.adminprofiles),
    agentprofiles: useSelector((store: IReducerStore) => store.agentprofiles),
    pauses: useSelector((store: IReducerStore) => store.pauses),
    conversation_filters: useSelector((store: IReducerStore) => store.conversation_filters),
    tabs: useSelector((store: IReducerStore) => store.tabs),
    dispositions: useSelector((store: IReducerStore) => store.dispositions),
    queues: useSelector((store: IReducerStore) => store.queues),
    mail_accounts: useSelector((store: IReducerStore) => store.mail_accounts),
    stats_widgets: useSelector((store: IReducerStore) => store.stats_widgets),
  };
  const [activeSelectors, setActiveSelectors, ] = useState<Array<string>>([]);
  const isInactive = useIsInactive();

  useEffect(() => {
    if (isInactive && connection)
      connection.disconnect();
  }, [isInactive, ]);

  const onConnect = useCallback(async (socket, is_reconnect: boolean = false, subscriptions: Nullable<Record<string, Array<any>>> = null) => {

    if (!auth || !socket)
      return;

    global.socketId = socket.id;

    if (is_reconnect) {
      if (subscriptions)
        socket.emit(ADD_SUBSCRIPTIONS, subscriptions);

      return;
    }

    const promises: Array<Promise<any>> = [];
    const selectors: Array<string> = [];

    if (!auth.is_wallboard) {
      promises.push(dispatch(actionFetchUsers()));
      promises.push(dispatch(actionFetchOrganizations()));
      if (isAdmin(auth.user)) {
        promises.push(dispatch(actionFetchAdminprofiles()));
        selectors.push('adminprofiles');
      }

      if (isAgent(auth.user)) {
        promises.push(dispatch(actionFetchAgentprofiles()));
        selectors.push('agentprofiles');
        promises.push(dispatch(actionFetchPauses()));
        selectors.push('pauses');

        if (auth.user.main) {
          promises.push(dispatch(actionFetchTabs()));
          selectors.push('tabs');
        }

      }

      promises.push(dispatch(actionFetchConversationFilters()));
      selectors.push('conversation_filters');
      promises.push(dispatch(actionFetchDispositions()));
      selectors.push('dispositions');
      promises.push(dispatch(actionFetchMailAccounts()));
      selectors.push('mail_accounts');

      promises.push(dispatch(actionFetchQueues()));
      selectors.push('queues');
    }
    else {
      // dispatch(actionSetOrganizations([
      //   { id: 3, timezone: 100 } as any,
      // ]));
      promises.push(dispatch(actionFetchStatsWidgets()));
      selectors.push('stats_widgets');
    }

    await Promise.all(promises);
    setActiveSelectors(selectors);

  }, [auth, dispatch, ]);

  useEffect(() => {
    if (!activeSelectors.length)
      return;

    if (activeSelectors.every(s => selectors[s].loaded))
      setLoaded(true);

  }, [selectors, activeSelectors, ]);

  const loadSocket = useCallback((is_reconnect: boolean = false, subscriptions: Nullable<Record<string, Array<any>>> = null) => {

    const options: Partial<ManagerOptions & SocketOptions> = {
      auth: { token: auth.access_token, },
      reconnection: false,
      autoConnect: true,
      transports: ['websocket', ],
    };

    let expected_termination = false;

    const s = io(process.env.REACT_APP_BASE_SOCKET_ENDPOINT || 'http://localhost:8000', options)
      .on('connect', () => onConnect(s, is_reconnect, subscriptions))
      .on('disconnect', () => {
        if (expected_termination)
          return;

        if (!hasException.current) {
          dispatch(actionSetSocketDisconnected(true));
          AlertError(t, {
            content: t('SOCKET_DISCONNECTED'),
          });
          setTimeout(() => {
            BrowserNotification(
              'SOCKET_DISCONNECTED',
              t('ERROR'),
              t('SOCKET_DISCONNECTED'),
              [
                {
                  subject: 'SOCKET_DISCONNECTED',
                  action: 'SOCKET_DISCONNECTED',
                  title: t('OK'),
                },
              ],
              '/icons/error.png'
            );
          }, 2000);
        }
      })
      .on('connect_error', (error) => {
        AlertError(t, {
          content: t('SOCKET_CONNECTION_ERROR', { error: error.message, }),
          onOk: () => {
            window.location.href = '/';
          },
        });
      });

    connection = s;

    s
      .on('exception', (exception: {status: number}) => {
        AlertError(t, {
          content: t(exception.status === 403 ? 'SOCKET_DISCONNECTED_USER_ACTIVE_NOT' : 'SOCKET_DISCONNECTED'),
          onOk: () => {
            window.location.href = '/';
          },
        });
        hasException.current = true;
        setTimeout(() => {
          window.location.href = '/';
        }, 1e4);
      })
      .on(CONTAINER_IS_TERMINATING, subscriptions => {
        loadSocket(true, subscriptions);
        expected_termination = true;
        //s.emit(ADD_SUBSCRIPTIONS, subscriptions);
        setTimeout(() => {
          s.disconnect();
        }, 1000);
      })
      // .on(ADDED_SUBSCRIPTIONS, payload => {
      //   s.disconnect();
      // })
      .on(FORCE_USER_LOGOUT, () => {
        s.disconnect();
        signout();
      })
      .on('user_status:update', (user_status: IPreviewUserStatus) => {
        dispatch(actionUpdateUserStatus(user_status));
        if (auth.user_status.id === user_status.id)
          dispatch(authSetUserStatusState({ ...auth.user_status, ...user_status, }));
      })
      .on('user:update', (user: IPreviewUser) => {
        dispatch(actionUpdateUser(user));
        if (auth.user.id === user.id)
          dispatch(authSetUserState(user));
      })
      .on(JourneyActionEnum.OpenBrowserTab, (payload) => payload?.url && window.open(payload.url, '_blank')?.focus())
      .on('user_status:softdelete', (user_status: any) => dispatch(actionUpdateUserStatus(user_status)))
      .on('user:insert', (user: IPreviewUser) => dispatch(actionAddUser(user)))
      .on('user:softdelete', (user: IPreviewUser) => dispatch(actionUpdateUser(user)))
      .on('organization:insert', (organization: IPreviewOrganization) => dispatch(actionAddOrganization(organization)))
      .on('organization:update', (organization: IPreviewOrganization) => dispatch(actionUpdateOrganization(organization)))
      .on('organization:softdelete', (organization: IPreviewOrganization) => dispatch(actionUpdateOrganization(organization)))
      .on('notification_user:insert', (notification_user: IPreviewNotificationUser) => dispatch(actionAddNotificationUser(notification_user)))
      .on('notification_user:update', (notification_user: IPreviewNotificationUser) => dispatch(actionUpdateNotificationUser(notification_user)))
      .on('notification_user:softdelete', (notification_user: IPreviewNotificationUser) => dispatch(actionUpdateNotificationUser(notification_user)))
      .on('agentprofile:insert', (agentprofile: IPreviewAgentprofile) => dispatch(actionAddAgentprofile(agentprofile)))
      .on('agentprofile:update', (agentprofile: IPreviewAgentprofile) => dispatch(actionUpdateAgentprofile(agentprofile)))
      .on('agentprofile:softdelete', (agentprofile: IPreviewAgentprofile) => dispatch(actionUpdateAgentprofile(agentprofile)))
      .on('pause:insert', (pause: IPreviewPause) => dispatch(actionAddPause(pause)))
      .on('pause:update', (pause: IPreviewPause) => dispatch(actionUpdatePause(pause)))
      .on('pause:softdelete', (pause: IPreviewPause) => dispatch(actionUpdatePause(pause)))
      .on('conversation_filter:insert', (conversation_filter: IPreviewConversationFilter) => dispatch(actionAddConversationFilter(conversation_filter)))
      .on('conversation_filter:update', (conversation_filter: IPreviewConversationFilter) => dispatch(actionUpdateConversationFilter(conversation_filter)))
      .on('conversation_filter:delete', (conversation_filter: IPreviewConversationFilter['id']) => dispatch(actionRemoveConversationFilter(conversation_filter)))
      .on('tab:insert', (tab: IPreviewTab) => {
        dispatch(actionAddTab(tab));
        if (tab.main_channel === ChannelEnum.Voice) {
          dispatch(actionSetFocusedConversation(tab.conversation_id));
          dispatch(actionSetHandlingConversation(tab.conversation_id));
        }
      })
      .on('tab:update', (tab: IPreviewTab) => dispatch(actionUpdateTab(tab)))
      .on('tab:delete', (tab: IPreviewTab['id']) => dispatch(actionRemoveTab(tab)))
      .on('disposition:insert', (disposition: IPreviewDisposition) => dispatch(actionAddDisposition(disposition)))
      .on('disposition:update', (disposition: IPreviewDisposition) => dispatch(actionUpdateDisposition(disposition)))
      .on('disposition:softdelete', (disposition: IPreviewDisposition) => dispatch(actionUpdateDisposition(disposition)))
      .on('tag:insert', (tag: IPreviewTag) => dispatch(actionAddTag(tag)))
      .on('tag:update', (tag: IPreviewTag) => dispatch(actionUpdateTag(tag)))
      .on('tag:softdelete', (tag: IPreviewTag) => dispatch(actionUpdateTag(tag)))
      .on('queue:insert', (queue: IPreviewQueue) => dispatch(actionAddQueue(queue)))
      .on('queue:update', (queue: IPreviewQueue) => dispatch(actionUpdateQueue(queue)))
      .on('queue:softdelete', (queue: IPreviewQueue) => dispatch(actionUpdateQueue(queue)))
      .on('mail_account:insert', (mail_account: IPreviewMailAccount) => dispatch(actionAddMailAccount(mail_account)))
      .on('mail_account:update', (mail_account: IPreviewMailAccount) => dispatch(actionUpdateMailAccount(mail_account)))
      .on('mail_account:softdelete', (mail_account: IPreviewMailAccount) => dispatch(actionUpdateMailAccount(mail_account)))
      .on('adminprofile:insert', (adminprofile: IPreviewAdminprofile) => dispatch(actionAddAdminprofile(adminprofile)))
      .on('adminprofile:update', (adminprofile: IPreviewAdminprofile) => dispatch(actionUpdateAdminprofile(adminprofile)))
      .on('adminprofile:softdelete', (adminprofile: IPreviewAdminprofile) => dispatch(actionUpdateAdminprofile(adminprofile)))
      .on('canned_answer:insert', (canned_answer: IPreviewCannedAnswer) => dispatch(actionAddCannedAnswer(canned_answer)))
      .on('canned_answer:update', (canned_answer: IPreviewCannedAnswer) => dispatch(actionUpdateCannedAnswer(canned_answer)))
      .on('canned_answer:softdelete', (canned_answer: IPreviewCannedAnswer) => dispatch(actionUpdateCannedAnswer(canned_answer)))
      .on('signature:insert', (signature: IPreviewSignature) => dispatch(actionAddSignature(signature)))
      .on('signature:update', (signature: IPreviewSignature) => dispatch(actionUpdateSignature(signature)))
      .on('signature:softdelete', (signature: IPreviewSignature) => dispatch(actionUpdateSignature(signature)))
      .on('draft:insert', (draft: IPreviewDraft) => dispatch(actionAddConversationDraft(draft)))
      .on('draft:update', (draft: IPreviewDraft) => dispatch(actionUpdateConversationDraft(draft)))
      .on('draft:delete', (draft_id: IPreviewDraft['id']) => dispatch(actionRemoveConversationDraft(draft_id)))
      .on('conversation_watcher:insert', (conversation_watcher: IPreviewConversationWatcher) => dispatch(actionAddConversationConversationWatcher(conversation_watcher)))
      //.on('conversation_watcher:delete', (conversation_watcher_id: IPreviewConversationWatcher['id']) => dispatch(actionRemoveConversationConversationWatcher(conversation_watcher_id)))
      .on('conversation_watcher:unlinked', (conversation_watcher: IPreviewConversationWatcher) => dispatch(actionRemoveConversationConversationWatcher(conversation_watcher)))
      .on('mail_message:insert', (mail_message: IPreviewMailMessage) => dispatch(actionAddConversationMessage(mail_message)))
      .on('mail_message:update', (mail_message: IPreviewMailMessage) => dispatch(actionUpdateConversationMessage(mail_message)))
      .on('form_submission:insert', (form_submission: IPreviewMailMessage) => dispatch(actionAddConversationMessage(form_submission)))
      .on('form_submission:update', (form_submission: IPreviewMailMessage) => dispatch(actionUpdateConversationMessage(form_submission)))
      .on('internal_note:insert', (internal_note: IPreviewInternalNote) => dispatch(actionAddConversationMessage(internal_note)))
      .on('internal_note:update', (internal_note: IPreviewInternalNote) => dispatch(actionUpdateConversationMessage(internal_note)))
      .on('voice_recording:insert', (voice_recording: IPreviewVoiceRecording) => dispatch(actionAddConversationVoiceRecording(voice_recording)))
      .on('voice_recording:update', (voice_recording: IPreviewVoiceRecording) => dispatch(actionUpdateConversationVoiceRecording(voice_recording)))
      .on('voice_voicemail_message:insert', (voice_voicemail_message: IPreviewVoiceVoicemailMessage) => dispatch(actionAddConversationVoiceVoicemailMessage(voice_voicemail_message)))
      .on('voice_voicemail_message:update', (voice_voicemail_message: IPreviewVoiceVoicemailMessage) => dispatch(actionUpdateConversationVoiceVoicemailMessage(voice_voicemail_message)))
      .on('conversation:insert', (conversation: any) => dispatch(actionAddConversation(conversation)))
      .on('conversation:update', (conversation: any) => dispatch(actionUpdateConversation(conversation)))
      .on('FULL_CONVERSATION:update', (conversation: any) => dispatch(conversationsUpdateFullState(conversation)))
      .on('conversation:softdelete', (conversation: any) => dispatch(actionUpdateConversation(conversation)))
      .on('user_status:insert', (user_status: IPreviewUserStatus) => dispatch(actionAddUserStatus(user_status)))
      .on('user_status:delete', (user_status: IPreviewUserStatus['id']) => dispatch(actionRemoveUserStatus(user_status)))
      .on('form:insert', (form: IPreviewForm) => dispatch(actionAddForm(form)))
      .on('form:update', (form: IPreviewForm) => dispatch(actionUpdateForm(form)))
      .on('form:softdelete', (form: IPreviewForm) => dispatch(actionUpdateForm(form)))
      .on('form_website:insert', (form_website: IPreviewFormWebsite) => dispatch(actionAddFormWebsite(form_website)))
      .on('form_website:update', (form_website: IPreviewFormWebsite) => dispatch(actionUpdateFormWebsite(form_website)))
      .on('form_website:softdelete', (form_website: IPreviewFormWebsite) => dispatch(actionUpdateFormWebsite(form_website)))
      .on('time_interval:insert', (time_interval: IPreviewTimeInterval) => dispatch(actionAddTimeInterval(time_interval)))
      .on('time_interval:update', (time_interval: IPreviewTimeInterval) => dispatch(actionUpdateTimeInterval(time_interval)))
      .on('time_interval:softdelete', (time_interval: IPreviewTimeInterval) => dispatch(actionUpdateTimeInterval(time_interval)))
      .on('field:insert', (field: IPreviewField) => dispatch(actionAddField(field)))
      .on('field:update', (field: IPreviewField) => dispatch(actionUpdateField(field)))
      .on('field:softdelete', (field: IPreviewField) => dispatch(actionUpdateField(field)))
      .on('operational_hours:insert', (operational_hours: IPreviewOperationalHours) => dispatch(actionAddOperationalHours(operational_hours)))
      .on('operational_hours:update', (operational_hours: IPreviewOperationalHours) => dispatch(actionUpdateOperationalHours(operational_hours)))
      .on('operational_hours:softdelete', (operational_hours: IPreviewOperationalHours) => dispatch(actionUpdateOperationalHours(operational_hours)))
      .on('journey:insert', (journey: IPreviewJourney) => dispatch(actionAddJourney(journey)))
      .on('journey:update', (journey: IPreviewJourney) => dispatch(actionUpdateJourney(journey)))
      .on('journey:softdelete', (journey: IPreviewJourney) => dispatch(actionUpdateJourney(journey)))
      .on('journey_draft:insert', (journey_draft: IPreviewJourneyDraft) => dispatch(actionAddJourneyDraft(journey_draft)))
      .on('journey_draft:update', (journey_draft: IPreviewJourneyDraft) => dispatch(actionUpdateJourneyDraft(journey_draft)))
      .on('journey_draft:delete', (journey_draft_id: IPreviewJourneyDraft['id']) => dispatch(actionRemoveJourneyDraft(journey_draft_id)))
      .on('list:insert', (list: IPreviewList) => dispatch(actionAddList(list)))
      .on('list:update', (list: IPreviewList) => dispatch(actionUpdateList(list)))
      .on('list:softdelete', (list: IPreviewList) => dispatch(actionUpdateList(list)))
      .on('team:insert', (team: IPreviewTeam) => dispatch(actionAddTeam(team)))
      .on('team:update', (team: IPreviewTeam) => dispatch(actionUpdateTeam(team)))
      .on('team:softdelete', (team: IPreviewTeam) => dispatch(actionUpdateTeam(team)))
      .on('sla_policy:insert', (sla_policy: IPreviewSlaPolicy) => dispatch(actionAddSlaPolicy(sla_policy)))
      .on('sla_policy:update', (sla_policy: IPreviewSlaPolicy) => dispatch(actionUpdateSlaPolicy(sla_policy)))
      .on('sla_policy:softdelete', (sla_policy: IPreviewSlaPolicy) => dispatch(actionUpdateSlaPolicy(sla_policy)))
      .on('recently_searched:insert', (recently_searched: IPreviewRecentlySearched) => dispatch(actionAddRecentlySearched(recently_searched)))
      .on('recently_searched:update', (recently_searched: IPreviewRecentlySearched) => dispatch(actionUpdateRecentlySearched(recently_searched)))
      .on('recently_searched:delete', (recently_searched_id: IPreviewRecentlySearched['id']) => dispatch(actionRemoveRecentlySearched(recently_searched_id)))
      .on('recently_searched:clear', () => dispatch(actionClearAllRecentlySearched()))
      .on('recently_viewed_conversation:insert', (recently_viewed_conversation: IPreviewRecentlyViewedConversation) => dispatch(actionAddRecentlyViewedConversation(recently_viewed_conversation)))
      .on('recently_viewed_conversation:update', (recently_viewed_conversation: IPreviewRecentlyViewedConversation) => dispatch(actionUpdateRecentlyViewedConversation(recently_viewed_conversation)))
      .on('recently_viewed_conversation:delete', (recently_viewed_conversation_id: IPreviewRecentlyViewedConversation['id']) => dispatch(actionRemoveRecentlyViewedConversation(recently_viewed_conversation_id)))
      .on('recently_viewed_conversation:clear', () => dispatch(actionClearAllRecentlyViewed()))
      .on('voice_asterisk:insert', (voice_asterisk: IPreviewVoiceAsterisk) => dispatch(actionAddVoiceAsterisk(voice_asterisk)))
      .on('voice_asterisk:update', (voice_asterisk: IPreviewVoiceAsterisk) => dispatch(actionUpdateVoiceAsterisk(voice_asterisk)))
      .on('voice_asterisk:softdelete', (voice_asterisk: IPreviewVoiceAsterisk) => dispatch(actionUpdateVoiceAsterisk(voice_asterisk)))
      .on('voice_asterisk_downgrade:insert', (voice_asterisk_downgrade: IPreviewVoiceAsteriskDowngrade) => dispatch(actionAddVoiceAsteriskDowngrade(voice_asterisk_downgrade)))
      .on('voice_asterisk_downgrade:update', (voice_asterisk_downgrade: IPreviewVoiceAsteriskDowngrade) => dispatch(actionUpdateVoiceAsteriskDowngrade(voice_asterisk_downgrade)))
      .on('voice_asterisk_downgrade:softdelete', (voice_asterisk_downgrade: IPreviewVoiceAsteriskDowngrade) => dispatch(actionUpdateVoiceAsteriskDowngrade(voice_asterisk_downgrade)))
      .on('voice_trunk:insert', (voice_trunk: IPreviewVoiceTrunk) => dispatch(actionAddVoiceTrunk(voice_trunk)))
      .on('voice_trunk:update', (voice_trunk: IPreviewVoiceTrunk) => dispatch(actionUpdateVoiceTrunk(voice_trunk)))
      .on('voice_trunk:softdelete', (voice_trunk: IPreviewVoiceTrunk) => dispatch(actionUpdateVoiceTrunk(voice_trunk)))
      .on('voice_route:insert', (voice_route: IPreviewVoiceRoute) => dispatch(actionAddVoiceRoute(voice_route)))
      .on('voice_route:update', (voice_route: IPreviewVoiceRoute) => dispatch(actionUpdateVoiceRoute(voice_route)))
      .on('voice_route:softdelete', (voice_route: IPreviewVoiceRoute) => dispatch(actionUpdateVoiceRoute(voice_route)))
      .on('voice_voicemail:insert', (voice_voicemail: IPreviewVoiceVoicemail) => dispatch(actionAddVoiceVoicemail(voice_voicemail)))
      .on('voice_voicemail:update', (voice_voicemail: IPreviewVoiceVoicemail) => dispatch(actionUpdateVoiceVoicemail(voice_voicemail)))
      .on('voice_voicemail:softdelete', (voice_voicemail: IPreviewVoiceVoicemail) => dispatch(actionUpdateVoiceVoicemail(voice_voicemail)))
      .on('voice_internal_call:insert', (voice_internal_call: IPreviewVoiceInternalCall) => dispatch(actionAddVoiceInternalCall(voice_internal_call)))
      .on('voice_internal_call:update', (voice_internal_call: IPreviewVoiceInternalCall) => dispatch(actionUpdateVoiceInternalCall(voice_internal_call)))
      .on('voice_chanspy:insert', (voice_chanspy: IPreviewVoiceChanspy) => dispatch(actionAddVoiceChanspy(voice_chanspy)))
      .on('voice_chanspy:update', (voice_chanspy: IPreviewVoiceChanspy) => dispatch(actionUpdateVoiceChanspy(voice_chanspy)))
      .on('sound:insert', (sound: IPreviewSound) => dispatch(actionAddSound(sound)))
      .on('sound:update', (sound: IPreviewSound) => dispatch(actionUpdateSound(sound)))
      .on('sound:softdelete', (sound: IPreviewSound) => dispatch(actionUpdateSound(sound)))
      .on('journey_variable:insert', (journey_variable: IPreviewJourneyVariable) => dispatch(actionAddJourneyVariable(journey_variable)))
      .on('journey_variable:update', (journey_variable: IPreviewJourneyVariable) => dispatch(actionUpdateJourneyVariable(journey_variable)))
      .on('journey_variable:softdelete', (journey_variable: IPreviewJourneyVariable) => dispatch(actionUpdateJourneyVariable(journey_variable)))
      .on('journey_trigger:delete', (journey_trigger_id: IPreviewJourneyTrigger['id']) => dispatch(actionRemoveJourneyTrigger(journey_trigger_id)))
      .on('journey_trigger:insert', (journey_trigger: IPreviewJourneyTrigger) => dispatch(actionAddJourneyTrigger(journey_trigger)))
      .on('journey_trigger:update', (journey_trigger: IPreviewJourneyTrigger) => dispatch(actionUpdateJourneyTrigger(journey_trigger)))
      .on('voice_call:insert', (message: IPreviewVoiceCall) => dispatch(actionAddConversationMessage(message)) && dispatch(actionAddVoiceCall(message)))
      .on('voice_call:update', (message: IPreviewVoiceCall) => dispatch(actionUpdateConversationMessage(message)) && dispatch(actionUpdateVoiceCall(message)))
      .on('voice_call_bridge:insert', (bridge: IPreviewVoiceCallBridge) => dispatch(actionAddConversationVoiceCallBridge(bridge)))
      .on('voice_call_bridge:update', (bridge: IPreviewVoiceCallBridge) => dispatch(actionUpdateConversationVoiceCallBridge(bridge)))
      .on('stats_report:insert', (stats_report: IPreviewStatsReport) => dispatch(actionAddStatsReport(stats_report)))
      .on('stats_report:update', (stats_report: IPreviewStatsReport) => dispatch(actionUpdateStatsReport(stats_report)))
      .on('stats_report:delete', (stats_report_id: IPreviewStatsReport['id']) => dispatch(actionRemoveStatsReport(stats_report_id)))
      .on('stats_widget:action:data', (stats_widget_data: IStatsWidgetDataPayload) => dispatch(actionUpdateStatsWidgetData(stats_widget_data)))
      .on('stats_widget:insert', (stats_widget: IPreviewStatsWidget) => dispatch(actionAddStatsWidget(stats_widget)))
      .on('stats_widget:update', (stats_widget: IPreviewStatsWidget) => dispatch(actionUpdateStatsWidget(stats_widget)))
      .on('stats_widget:delete', (stats_widget_id: IPreviewStatsWidget['id']) => dispatch(actionRemoveStatsWidget(stats_widget_id)))
      .on('stats_report_widget:insert', (stats_report_widget: IPreviewStatsReportWidget) => dispatch(actionAddStatsReportWidget(stats_report_widget)))
      .on('stats_report_widget:update', (stats_report_widget: IPreviewStatsReportWidget) => dispatch(actionUpdateStatsReportWidget(stats_report_widget)))
      .on('stats_report_widget:delete', (stats_report_widget_id: IPreviewStatsReportWidget['id']) => dispatch(actionRemoveStatsReportWidget(stats_report_widget_id)))
      .on('stats_report_preview_widget:insert', (stats_report_preview_widget: IPreviewStatsReportPreviewWidget) => dispatch(actionAddStatsReportPreviewWidget(stats_report_preview_widget)))
      .on('stats_report_preview_widget:update', (stats_report_preview_widget: IPreviewStatsReportPreviewWidget) => dispatch(actionUpdateStatsReportPreviewWidget(stats_report_preview_widget)))
      .on('stats_report_preview_widget:delete', (stats_report_preview_widget_id: IPreviewStatsReportPreviewWidget['id']) => dispatch(actionRemoveStatsReportPreviewWidget(stats_report_preview_widget_id)))
      .on('stats_custom_metric:insert', (stats_custom_metric: IPreviewStatsCustomMetric) => dispatch(actionAddStatsCustomMetric(stats_custom_metric)))
      .on('stats_custom_metric:update', (stats_custom_metric: IPreviewStatsCustomMetric) => dispatch(actionUpdateStatsCustomMetric(stats_custom_metric)))
      .on('stats_custom_metric:softdelete', (stats_custom_metric: IPreviewStatsCustomMetric) => dispatch(actionUpdateStatsCustomMetric(stats_custom_metric)))
      .on('tariff:insert', (tariff: IPreviewTariff) => dispatch(actionAddTariff(tariff)))
      .on('tariff:update', (tariff: IPreviewTariff) => dispatch(actionUpdateTariff(tariff)))
      .on('tariff:softdelete', (tariff: IPreviewTariff) => dispatch(actionUpdateTariff(tariff)))
      .on('tariff_parent:insert', (tariff_parent: IPreviewTariffParent) => dispatch(actionAddTariffParent(tariff_parent)))
      .on('tariff_parent:update', (tariff_parent: IPreviewTariffParent) => dispatch(actionUpdateTariffParent(tariff_parent)))
      .on('tariff_parent:softdelete', (tariff_parent: IPreviewTariffParent) => dispatch(actionUpdateTariffParent(tariff_parent)))
      .on('superadmin_profile:insert', (superadmin_profile: IPreviewSuperadminProfile) => dispatch(actionAddSuperadminProfile(superadmin_profile)))
      .on('superadmin_profile:update', (superadmin_profile: IPreviewSuperadminProfile) => dispatch(actionUpdateSuperadminProfile(superadmin_profile)))
      .on('superadmin_profile:softdelete', (superadmin_profile: IPreviewSuperadminProfile) => dispatch(actionUpdateSuperadminProfile(superadmin_profile)))
      .on('invoice:insert', (invoice: IPreviewInvoice) => dispatch(actionAddInvoice(invoice)))
      .on('invoice:update', (invoice: IPreviewInvoice) => dispatch(actionUpdateInvoice(invoice)))
      .on('invoice:delete', (invoice_id: IPreviewInvoice['id']) => dispatch(actionRemoveInvoice(invoice_id)))
      .on('prepaid_credit:insert', (prepaid_credit: IPreviewPrepaidCredit) => dispatch(actionAddPrepaidCredit(prepaid_credit)))
      .on('prepaid_credit:update', (prepaid_credit: IPreviewPrepaidCredit) => dispatch(actionUpdatePrepaidCredit(prepaid_credit)))
      .on('prepaid_credit:delete', (prepaid_credit_id: IPreviewPrepaidCredit['id']) => dispatch(actionRemovePrepaidCredit(prepaid_credit_id)))
      .on('phonenumber_monthly_cost:insert', (phonenumber_monthly_cost: IPreviewPhonenumberMonthlyCost) => dispatch(actionAddPhonenumberMonthlyCost(phonenumber_monthly_cost)))
      .on('phonenumber_monthly_cost:update', (phonenumber_monthly_cost: IPreviewPhonenumberMonthlyCost) => dispatch(actionUpdatePhonenumberMonthlyCost(phonenumber_monthly_cost)))
      .on('phonenumber_monthly_cost:softdelete', (phonenumber_monthly_cost: IPreviewPhonenumberMonthlyCost) => dispatch(actionUpdatePhonenumberMonthlyCost(phonenumber_monthly_cost)))
      .on('phonenumber_monthly_cost_parent:insert', (phonenumber_monthly_cost_parent: IPreviewPhonenumberMonthlyCostParent) => dispatch(actionAddPhonenumberMonthlyCostParent(phonenumber_monthly_cost_parent)))
      .on('phonenumber_monthly_cost_parent:update', (phonenumber_monthly_cost_parent: IPreviewPhonenumberMonthlyCostParent) => dispatch(actionUpdatePhonenumberMonthlyCostParent(phonenumber_monthly_cost_parent)))
      .on('phonenumber_monthly_cost_parent:softdelete', (phonenumber_monthly_cost_parent: IPreviewPhonenumberMonthlyCostParent) => dispatch(actionUpdatePhonenumberMonthlyCostParent(phonenumber_monthly_cost_parent)))
      .on('phonenumber_setup_cost:insert', (phonenumber_setup_cost: IPreviewPhonenumberSetupCost) => dispatch(actionAddPhonenumberSetupCost(phonenumber_setup_cost)))
      .on('phonenumber_setup_cost:update', (phonenumber_setup_cost: IPreviewPhonenumberSetupCost) => dispatch(actionUpdatePhonenumberSetupCost(phonenumber_setup_cost)))
      .on('phonenumber_setup_cost:softdelete', (phonenumber_setup_cost: IPreviewPhonenumberSetupCost) => dispatch(actionUpdatePhonenumberSetupCost(phonenumber_setup_cost)))
      .on('phonenumber_setup_cost_parent:insert', (phonenumber_setup_cost_parent: IPreviewPhonenumberSetupCostParent) => dispatch(actionAddPhonenumberSetupCostParent(phonenumber_setup_cost_parent)))
      .on('phonenumber_setup_cost_parent:update', (phonenumber_setup_cost_parent: IPreviewPhonenumberSetupCostParent) => dispatch(actionUpdatePhonenumberSetupCostParent(phonenumber_setup_cost_parent)))
      .on('phonenumber_setup_cost_parent:softdelete', (phonenumber_setup_cost_parent: IPreviewPhonenumberSetupCostParent) => dispatch(actionUpdatePhonenumberSetupCostParent(phonenumber_setup_cost_parent)))
      .on('phonenumber_purchase:insert', (phonennumber_purchase: IPreviewPhonenumberPurchase) => dispatch(actionAddPhonenumberPurchase(phonennumber_purchase)))
      .on('phonenumber_purchase:update', (phonennumber_purchase: IPreviewPhonenumberPurchase) => dispatch(actionUpdatePhonenumberPurchase(phonennumber_purchase)))
      .on('release:insert', (release: IPreviewRelease) => dispatch(actionAddRelease(release)))
      .on('release:update', (release: IPreviewRelease) => dispatch(actionUpdateRelease(release)))
      .on('api_key:insert', (api_key: IPreviewApiKey) => dispatch(actionAddApiKey(api_key)))
      .on('api_key:update', (api_key: IPreviewApiKey) => dispatch(actionUpdateApiKey(api_key)))
      .on('api_key:delete', (api_key_id: IPreviewApiKey['id']) => dispatch(actionRemoveApiKey(api_key_id)))
      .on('client:insert', (client: IPreviewClient) => dispatch(actionAddClient(client)))
      .on('client:update', (client: IPreviewClient) => dispatch(actionUpdateClient(client)))
      .on('client:softdelete', (client: IPreviewClient) => dispatch(actionUpdateClient(client)))
      .on('skill:insert', (skill: IPreviewSkill) => dispatch(actionAddSkill(skill)))
      .on('skill:update', (skill: IPreviewSkill) => dispatch(actionUpdateSkill(skill)))
      .on('skill:softdelete', (skill: IPreviewSkill) => dispatch(actionUpdateSkill(skill)))
      .on('wallboard:insert', (wallboard: IPreviewWallboard) => dispatch(actionAddWallboard(wallboard)))
      .on('wallboard:update', (wallboard: IPreviewWallboard) => dispatch(actionUpdateWallboard(wallboard)))
      .on('wallboard:softdelete', (wallboard: IPreviewWallboard) => dispatch(actionUpdateWallboard(wallboard)))
      .on('gdpr:insert', (gdpr: IPreviewGdpr) => dispatch(actionAddGdpr(gdpr)))
      .on('gdpr:update', (gdpr: IPreviewGdpr) => dispatch(actionUpdateGdpr(gdpr)))
      .on('gdpr:delete', (gdpr_id: IPreviewGdpr['id']) => dispatch(actionRemoveGdpr(gdpr_id)))
    ;

  }, [auth, dispatch, t, onConnect, ]);

  useEffect(() => {
    if (isPrint)
      return setLoaded(true);

    if (!isPrint && (auth?.user?.id || auth?.is_wallboard) && !loading && !loaded) {
      setLoading(true);
      loadSocket();
    }
  }, [isPrint, auth?.user?.id, dispatch, t, onConnect, loading, loaded, loadSocket, ]);

  // useEffect(() => {
  //   return ()=> socket?.disconnect();
  // }, []);

  return (
    <>
      {
        loaded ? children : <AppSpinner />
      }
    </>
  );
});
