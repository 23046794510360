import { EditIcon, } from './Edit';
import { SvgIcon, } from './SvgIcon';
import { MailIcon, } from './Mail';
import { PhoneCallIcon, } from './Phone';
import { VoicemailIcon, } from './Voicemail';
import { RecordIcon, RecordStopIcon, } from './Record';
import { NumbersIcon, } from './Numbers';
import { HangupIcon, } from './Hangup';
import { DialIcon, } from './Dial';
import { MusicIcon, } from './Music';
import { MenuIcon, } from './Menu';
import { QueueIcon, } from './Queue';
import { UserIcon, UserXIcon, } from './User';
import { SaveIcon, } from './Save';
import { CloseIcon, } from './Close';
import { SendIcon, } from './Send';
import { ClockIcon, } from './Clock';
import { JourneyIcon, } from './Journey';
import { WhenIcon, } from './When';
import { TimerIcon, } from './Timer';
import { FormIcon, } from './Form';
import { ApiIcon, } from './Api';
import { HelpCircleIcon, } from './Help';
import { LanguageIcon, } from '@Assets/icons/Language';
import { NoteIcon, } from '@Assets/icons/Note';
import { BrowserIcon, } from '@Assets/icons/Browser';

export * from './Align';
export * from './Attachment';
export * from './Save';
export * from './Refresh';
export * from './Image';
export * from './Inbox';
export * from './User';
export * from './Trending';
export * from './Bookmark';
export * from './Archive';
export * from './Bell';
export * from './Edit';
export * from './Shrink';
export * from './ExternalLink';
export * from './Clock';
export * from './Chat';
export * from './Mail';
export * from './Sms';
export * from './Phone';
export * from './BookOpen';
export * from './Logout';
export * from './Eye';
export * from './Help';
export * from './Search';
export * from './Mic';
export * from './Volume';
export * from './Framer';
export * from './Dialpad';
export * from './MoreHorizontal';
export * from './Google';
export * from './Microsoft';
export * from './Lock';
export * from './Menu';
export * from './Terminal';
export * from './Activity';
export * from './Box';
export * from './Zap';
export * from './GitPullRequest';
export * from './Settings';
export * from './Trash';
export * from './Arrows';
export * from './Corners';
export * from './More';
export * from './Minimize';
export * from './Maximize';
export * from './X';
export * from './Star';
export * from './Flag';
export * from './Headphones';
export * from './List';
export * from './Filter';
export * from './Check';
export * from './Asc';
export * from './Desc';
export * from './Camera';
export * from './Code';
export * from './Circle';
export * from './Warning';
export * from './Plus';
export * from './Chevron';
export * from './Shuffle';
export * from './Tag';
export * from './Merge';
export * from './Move';
export * from './Download';
export * from './Upload';
export * from './Record';
export * from './Pause';
export * from './CallTransfer';
export * from './Hangup';
export * from './Play';
export * from './Voicemail';
export * from './Numbers';
export * from './Dial';
export * from './Music';
export * from './Queue';
export * from './Close';
export * from './Send';
export * from './Journey';
export * from './Tool';
export * from './When';
export * from './Timer';
export * from './Form';
export * from './Api';
export * from './CallAdd';
export * from './CallMissed';
export * from './ChanSpy';
export * from './ConversationList';
export * from './VoiceArrow';
export * from './VoiceMerge';
export * from './TwoFA';
export * from './Key';
export * from './Language';
export * from './Info';
export * from './Error';
export * from './Pdf';
export * from './File';
export * from './FileText';
export * from './WidgetPieIcon';
export * from './WidgetRadarIcon';
export * from './WidgetBarIcon';
export * from './WidgetTableIcon';
export * from './WidgetGridIcon';
export * from './WidgetLineIcon';
export * from './WidgetTextIcon';
export * from './WidgetPolarIcon';
export * from './WidgetTimelineIcon';
export * from './LiveIcon';
export * from './Percent';
export * from './GridIcon';
export * from './DashboardIcon';
export * from './ReportIcon';
export * from './CopyIcon';
export * from './ZoomIn';
export * from './ZoomOut';
export * from './Minus';
export * from './Square';
export * from './Money';
export * from './Smartphone';
export * from './Emergency';
export * from './Moon';
export * from './Sun';
export * from './Superadmin';
export * from './Audit';
export * from './Table';
export * from './Clone';
export * from './Unknown';
export * from './Note';
export * from './Globe';
export * from './Shield';
export * from './Browser';

export const IconSet: Record<string, typeof SvgIcon> = {
  Edit: EditIcon,
  Voice: PhoneCallIcon,
  Mail: MailIcon,
  Voicemail: VoicemailIcon,
  Record: RecordIcon,
  RecordStop: RecordStopIcon,
  Numbers: NumbersIcon,
  Hangup: HangupIcon,
  Dial: DialIcon,
  Music: MusicIcon,
  Menu: MenuIcon,
  Queue: QueueIcon,
  User: UserIcon,
  Form: FormIcon,
  Save: SaveIcon,
  Close: CloseIcon,
  Send: SendIcon,
  Clock: ClockIcon,
  Journey: JourneyIcon,
  When: WhenIcon,
  Timer: TimerIcon,
  Api: ApiIcon,
  HelpCircle: HelpCircleIcon,
  Language: LanguageIcon,
  UserX: UserXIcon,
  Note: NoteIcon,
  Browser: BrowserIcon,
};
