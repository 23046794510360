import React, { useCallback, useEffect, useMemo, useState, } from 'react';

import { useTranslation, } from 'react-i18next';
import { useNavigate, } from 'react-router-dom';
import {
  TariffAddForm,
  TariffAddInitialValues,
  TariffComponents,
  TariffAdditionalParams,
  TariffFormOptions,
  onTariffFormSaved,
  ITariffFormOptionsProps,
  AlertError,
  queryParam,
} from '@Utils';
import { AtlasForm, IFormOptions, } from 'atlas-form';
import { CrudEnum, ISharedCreateTariff, PermissionEnum, } from 'atlas-shared';
import { createTariff, fetchTariff, getTariffCreateSchema, } from '@Api';
import { RuleProtected, } from '@Containers';
import { withAdd, IWithAddProps, } from '@Hocs/with.add';
import { useAbilities, useAuth, useTariffParents, useOrganizations, } from '@Hooks';
import { AppSpinner, } from '@Components';
import { useAppDispatch, } from '@Store';

interface IProps extends IWithAddProps<ISharedCreateTariff> {}

const TariffFormAdd = ({ values, setValues, save, formSchema, schemaLoading, saving, initialValues, title, onSaved, onCancel, onChange, hasAdvanced = false, }: IProps) => {
  const tariff_parents = useTariffParents();
  const organizations = useOrganizations();
  const abilities = useAbilities();
  const { t, } = useTranslation();
  const navigate = useNavigate();
  const auth = useAuth();
  const dispatch = useAppDispatch();

  const [cloned, setCloned, ] = useState<Partial<ISharedCreateTariff>>();

  const param_props: ITariffFormOptionsProps = {
    t,
    is_new: true,
    abilities,
    tariff_parents: tariff_parents.tariff_parents,
    organizations: organizations.organizations,
  };
  const formLayout = useMemo(() => TariffAddForm(values, param_props, t), [values, tariff_parents, organizations, t, abilities, ]);
  const options: IFormOptions = useMemo(() => TariffFormOptions(values || TariffAddInitialValues, param_props, t), [values, tariff_parents, organizations, t, abilities, ]);
  
  const onValuesChange = useCallback((changedValue: any, values: ISharedCreateTariff, form) => {
    setValues(values);
    onChange?.(values, changedValue, form);
  }, [setValues, ]);
  const onFinish = useCallback((values: ISharedCreateTariff) => save(() => createTariff(values).then(
    res => onSaved ? onSaved(res, navigate) : onTariffFormSaved(t, 'add', res, navigate),
    err => {
      AlertError(t, {
        content: err,
      });
    }
  )), [save, navigate, t, ]);
  
  useEffect(() => {
    const id = queryParam('clone');

    if (id)
      fetchTariff(+id)
        .then(({
          tariff_parent_id,
          title,
          voice_tariff_list,
          voice_recording,
          voice_recording_storage,
          voice_recording_transcript,
          voice_tariff_roundup_strategy,
          voice_outbound_usage,
          voice_usage_roundup_strategy,
          voice_inbound_usage,
          voice_internal_usage,
          attachments_storage,
          email_inbound_usage,
          email_outbound_usage,
          form_inbound_usage,
        }) => {
          setCloned({
            tariff_parent_id,
            title,
            voice_tariff_list,
            voice_recording,
            voice_recording_storage,
            voice_recording_transcript,
            voice_tariff_roundup_strategy,
            voice_outbound_usage,
            voice_usage_roundup_strategy,
            voice_inbound_usage,
            voice_internal_usage,
            attachments_storage,
            email_inbound_usage,
            email_outbound_usage,
            form_inbound_usage,
          });
        });
    else
      setCloned({});
  }, []);
  
  if (schemaLoading || !cloned)
    return <AppSpinner />;

  if (!formSchema)
    return <>{t('SCHEMA_NOT_FOUND')}</>;
  
  if (!tariff_parents.loaded || !organizations.loaded)
    return <>{t('LOADING_DEPENDENCIES')}</>;

  return (
    <RuleProtected
      isAllowed={() => !values || abilities.can(null, CrudEnum.Insert, PermissionEnum.Tariff, values)}
    >
      <AtlasForm
        dispatch={dispatch}
        formLayout={formLayout}
        form={formSchema}
        options={options}
        components={TariffComponents}
        additionalParams={TariffAdditionalParams(auth, param_props)}
        onFinish={onFinish}
        onCancel={onCancel}
        onValuesChange={onValuesChange}
        initialValues={{ ...(cloned || {}), ...TariffAddInitialValues, ...initialValues, }}
        saving={saving}
        title={title ? t(title) : `${t('ADD')} ${t('TARIFF')}`}
        hasAdvanced={hasAdvanced}
      />
    </RuleProtected>
  );
};

export const TariffFormAddView = React.memo(withAdd(TariffFormAdd, getTariffCreateSchema, TariffAddInitialValues));
