import { TFunction, } from 'i18next';
import { FormLayoutTypes, IForm, IFormOptions, } from 'atlas-form';
import { filterRelated, } from '@Utils/relation';
import { IQueueFormOptionsProps, QueueFormOptions as QueueFormOptionsBase, QueueAdditionalParams as QueueAdditionalParamsBase, } from './abstract/queue.form.abstract';
import { ISharedCreateQueue, FormTypeEnum, IPreviewForm, ISharedQueue, IPreviewDisposition, } from 'atlas-shared';
import { IAuth, } from '@Store';

export const QueueFormOptions = (queue: ISharedCreateQueue | ISharedQueue, props: IQueueFormOptionsProps, t: TFunction): IFormOptions => ({
  ...QueueFormOptionsBase(queue, props, t),
  __dispositions: (it: ISharedQueue) => filterRelated<IPreviewDisposition>(it.organization_id || queue.organization_id, 'disposition', 'queue', props.dispositions, true, it => !it.global && !it.parents),
  conversation_form_id: (it: ISharedCreateQueue) => filterRelated<IPreviewForm>(it.organization_id || queue.organization_id, 'list', 'queue', props.forms, true, it => it.type === FormTypeEnum.Conversation),
});

export const QueueAdditionalParams: (auth: IAuth, props: IQueueFormOptionsProps) => IForm['additionalParams'] = (auth: IAuth, props: IQueueFormOptionsProps) => ({
  ...QueueAdditionalParamsBase(auth, props),
  __outbound_voice_routes: (__outbound_voice_routes, queue) => ({ hidden: !queue.voice_settings, }),
  mail_settings_skill_timeout_settings_timeout_one: { help: 'SKILL_TIMEOUT_ONE_DESCRIPTION', ui_layout: FormLayoutTypes.INLINE, },
  mail_settings_skill_timeout_settings_timeout_one_count: { hideLabel: true, },
  mail_settings_skill_timeout_settings_timeout_one_unit: { hideLabel: true, keep_default_sorting: true, },
  mail_settings_skill_timeout_settings_timeout_two: { help: 'SKILL_TIMEOUT_TWO_DESCRIPTION', ui_layout: FormLayoutTypes.INLINE, },
  mail_settings_skill_timeout_settings_timeout_two_count: { hideLabel: true, },
  mail_settings_skill_timeout_settings_timeout_two_unit: { hideLabel: true, keep_default_sorting: true, },
  mail_settings_skill_timeout_settings_timeout_three: { help: 'SKILL_TIMEOUT_THREE_DESCRIPTION', ui_layout: FormLayoutTypes.INLINE, },
  mail_settings_skill_timeout_settings_timeout_three_count: { hideLabel: true, },
  mail_settings_skill_timeout_settings_timeout_three_unit: { hideLabel: true, keep_default_sorting: true, },
  form_settings_skill_timeout_settings_timeout_one: { help: 'SKILL_TIMEOUT_ONE_DESCRIPTION', ui_layout: FormLayoutTypes.INLINE, },
  form_settings_skill_timeout_settings_timeout_one_count: { hideLabel: true, },
  form_settings_skill_timeout_settings_timeout_one_unit: { hideLabel: true, keep_default_sorting: true, },
  form_settings_skill_timeout_settings_timeout_two: { help: 'SKILL_TIMEOUT_TWO_DESCRIPTION', ui_layout: FormLayoutTypes.INLINE, },
  form_settings_skill_timeout_settings_timeout_two_count: { hideLabel: true, },
  form_settings_skill_timeout_settings_timeout_two_unit: { hideLabel: true, keep_default_sorting: true, },
  form_settings_skill_timeout_settings_timeout_three: { help: 'SKILL_TIMEOUT_THREE_DESCRIPTION', ui_layout: FormLayoutTypes.INLINE, },
  form_settings_skill_timeout_settings_timeout_three_count: { hideLabel: true, },
  form_settings_skill_timeout_settings_timeout_three_unit: { hideLabel: true, keep_default_sorting: true, },
});

export * from './abstract/queue.form.abstract';
