import { NavigateFunction, } from 'react-router';
import {
  FormLayoutTypes,
  IForm,
  IFormOptions,
} from 'atlas-form';
import { IAuth, IAbilities, } from '@Store';
import { ISharedCreateTariffParent, ISharedUpdateTariffParent, ISharedTariffParent, IPreviewOrganization, EnumOptions, InvoiceCurrencyEnum, VoiceTariffRoundupStrategy, } from 'atlas-shared';
import { TFunction, } from 'i18next';
import { entitiesToOptions, onFormSaved, } from '@Utils';

export interface ITariffParentFormOptionsProps {
  t: TFunction;
  is_new: boolean;
  abilities: IAbilities;
  organizations: Array<IPreviewOrganization>;
}

export const TariffParentFormOptions = (tariff_parent: ISharedCreateTariffParent | ISharedTariffParent, props: ITariffParentFormOptionsProps, t: TFunction): IFormOptions => ({
  organization: entitiesToOptions<IPreviewOrganization>(props.organizations, 'organization'),
  currency: EnumOptions('INVOICE_CURRENCY', InvoiceCurrencyEnum, t),
  voice_tariff_roundup_strategy: EnumOptions('VOICE_TARIFF_ROUNDUP_STRATEGY', VoiceTariffRoundupStrategy, t),
  voice_usage_roundup_strategy: EnumOptions('VOICE_TARIFF_ROUNDUP_STRATEGY', VoiceTariffRoundupStrategy, t),
});

export const TariffParentComponents: IForm['components'] = {};
export const TariffParentAdditionalParams: (auth: IAuth, props: ITariffParentFormOptionsProps) => IForm['additionalParams'] = (auth: IAuth, props: ITariffParentFormOptionsProps) => ({
});

export const TariffParentEditForm = (tariff_parent: ISharedTariffParent, props: ITariffParentFormOptionsProps, t: TFunction, label?: string): IForm => ({
  id: `tariff_parent_edit_${tariff_parent.id}`,
  label: label,
  ui_layout: FormLayoutTypes.HORIZONTAL,
  elements: [
    { ref: 'title', },
    { ref: 'currency', },
    { ref: 'voice_tariff_list', },
    { ref: 'voice_recording', },
    { ref: 'voice_recording_storage', },
    { ref: 'voice_recording_transcript', },
    { ref: 'voice_tariff_roundup_strategy', },
    { ref: 'voice_outbound_usage', },
    { ref: 'voice_usage_roundup_strategy', },
    { ref: 'voice_inbound_usage', },
    { ref: 'voice_internal_usage', },
    { ref: 'attachments_storage', },
    { ref: 'email_inbound_usage', },
    { ref: 'email_outbound_usage', },
    { ref: 'form_inbound_usage', },
  ],
});

export const TariffParentAddForm = (tariff_parent: ISharedCreateTariffParent, props: ITariffParentFormOptionsProps, t: TFunction, label?: string): IForm => ({
  id: 'tariff_parent_add',
  label: label,
  ui_layout: FormLayoutTypes.HORIZONTAL,
  elements: [
    { ref: 'title', },
    { ref: 'currency', },
    { ref: 'voice_tariff_list', },
    { ref: 'voice_recording', },
    { ref: 'voice_recording_storage', },
    { ref: 'voice_recording_transcript', },
    { ref: 'voice_tariff_roundup_strategy', },
    { ref: 'voice_outbound_usage', },
    { ref: 'voice_usage_roundup_strategy', },
    { ref: 'voice_inbound_usage', },
    { ref: 'voice_internal_usage', },
    { ref: 'attachments_storage', },
    { ref: 'email_inbound_usage', },
    { ref: 'email_outbound_usage', },
    { ref: 'form_inbound_usage', },
  ],
});

export const TariffParentAddInitialValues: ISharedCreateTariffParent = {} as ISharedCreateTariffParent;

export const TariffParentEditInitialValues = (tariff_parent: ISharedTariffParent): ISharedUpdateTariffParent => ({
  title: tariff_parent.title,
  currency: tariff_parent.currency,
  voice_tariff_list: tariff_parent.voice_tariff_list,
  voice_recording: tariff_parent.voice_recording,
  voice_recording_storage: tariff_parent.voice_recording_storage,
  voice_recording_transcript: tariff_parent.voice_recording_transcript,
  voice_tariff_roundup_strategy: tariff_parent.voice_tariff_roundup_strategy,
  voice_outbound_usage: tariff_parent.voice_outbound_usage,
  voice_usage_roundup_strategy: tariff_parent.voice_usage_roundup_strategy,
  voice_inbound_usage: tariff_parent.voice_inbound_usage,
  voice_internal_usage: tariff_parent.voice_internal_usage,
  attachments_storage: tariff_parent.attachments_storage,
  email_inbound_usage: tariff_parent.email_inbound_usage,
  email_outbound_usage: tariff_parent.email_outbound_usage,
  form_inbound_usage: tariff_parent.form_inbound_usage,
});

export const onTariffParentFormSaved = (t: TFunction, action: 'edit' | 'add', tariff_parent: ISharedTariffParent, navigate: NavigateFunction): void => {
  navigate(onFormSaved('tariff_parents'));
};
