import React from 'react';
import './channel.icon.scss';
import {
  ChanSpyIconCircled,
  ChatIconCircled,
  ChatInboundIconCircled,
  ChatOutboundIconCircled,
  DispositionNoteIconCircled,
  EditIconCircled,
  FormIconCircled, InboundIconCircled, InternalIconCircled,
  MailIconCircled, MailInboundAutoReplyIconCircled,
  MailInboundIconCircled, MailOutboundAutoReplyIconCircled,
  MailOutboundIconCircled, OrderIconCircled, OutboundIconCircled,
  PhoneIconCircled,
  PhoneInboundIconCircled,
  PhoneInboundLiveIconCircled,
  PhoneInternalIconCircled,
  PhoneInternalLiveIconCircled,
  PhoneOutboundIconCircled,
  PhoneOutboundLiveIconCircled,
  PhoneTransferIconCircled,
  PhoneTransferLiveIconCircled,
  TextMessageIconCircled,
  TextMessageInboundIconCircled,
  TextMessageOutboundIconCircled, UnknownIconCircled, VoiceInternalIconCircled, VoicemailIconCircled,
} from '@Assets/icons/channels/Channels';
import { ChannelEnum, DirectionEnum, Nullable, ContactCustomerJourneyTypeEnum, } from 'atlas-shared';
import { IPreviewConversationChannelProperty, } from 'atlas-shared/dist/interfaces/entities/abstract/conversation.interface.abstract';
import { useTranslation, } from 'react-i18next';
import { EmergencyIcon, } from '@Assets';

export interface IConversationChannelInterface {
  c?: IPreviewConversationChannelProperty['c'] | 'DispositionNote' | 'Voicemail' | 'ChanSpy' | 'VoiceInternal' | 'Emergency' | ContactCustomerJourneyTypeEnum | 'Unknown';
  d?: Nullable<IPreviewConversationChannelProperty['d']>;
  live?: boolean;
  transfer?: boolean;
  auto_reply?: boolean;
}

interface IProps {
  channel: IConversationChannelInterface;
  size?: number;
  padding?: number;
}

// direction, live, transfer
const VoiceChannelIcons: Array<[DirectionEnum, boolean, boolean, typeof PhoneIconCircled]> = [
  [DirectionEnum.InBound, false, false, PhoneInboundIconCircled, ],
  [DirectionEnum.InBound, true, false, PhoneInboundLiveIconCircled, ],
  [DirectionEnum.InBound, true, true, PhoneTransferLiveIconCircled, ],
  [DirectionEnum.InBound, false, true, PhoneTransferIconCircled, ],

  [DirectionEnum.Outbound, false, false, PhoneOutboundIconCircled, ],
  [DirectionEnum.Outbound, true, false, PhoneOutboundLiveIconCircled, ],
  [DirectionEnum.Outbound, true, true, PhoneTransferLiveIconCircled, ],
  [DirectionEnum.Outbound, false, true, PhoneTransferIconCircled, ],

  [DirectionEnum.Internal, false, false, PhoneInternalIconCircled, ],
  [DirectionEnum.Internal, true, false, PhoneInternalLiveIconCircled, ],
  [DirectionEnum.Internal, true, true, PhoneTransferLiveIconCircled, ],
  [DirectionEnum.Internal, false, true, PhoneTransferIconCircled, ],
];

export const ChannelIcon = React.memo(({ size = 18, channel: { c: channel, d: direction, live, transfer, auto_reply, }, padding, }: IProps) => {
  const { t, } = useTranslation();
  let Comp: typeof ChatIconCircled;

  if (channel)
    switch (channel) {
    case ChannelEnum.Chat:
      if (direction === DirectionEnum.InBound)
        Comp = ChatInboundIconCircled;
      else if (direction === DirectionEnum.Outbound)
        Comp = ChatOutboundIconCircled;
      else
        Comp = ChatIconCircled;

      break;
    case ChannelEnum.Mail:
      if (direction === DirectionEnum.InBound)
        Comp = auto_reply ? MailInboundAutoReplyIconCircled : MailInboundIconCircled;
      else if (direction === DirectionEnum.Outbound)
        Comp = auto_reply ? MailOutboundAutoReplyIconCircled : MailOutboundIconCircled;
      else
        Comp = MailIconCircled;

      break;
    case ChannelEnum.Sms:
      if (direction === DirectionEnum.InBound)
        Comp = TextMessageInboundIconCircled;
      else if (direction === DirectionEnum.Outbound)
        Comp = TextMessageOutboundIconCircled;
      else
        Comp = TextMessageIconCircled;

      break;
    case ChannelEnum.Voice:
      Comp = VoiceChannelIcons.find(([_direction, _live, _transfer, ]) => direction === _direction && !!live === _live && !!transfer === _transfer)?.[3] || PhoneIconCircled;

      break;
    case ChannelEnum.Form:
      Comp = FormIconCircled;
      break;
    case ChannelEnum.InternalNote:
      Comp = EditIconCircled;
      break;
    case 'VoiceInternal':
      Comp = VoiceInternalIconCircled;
      break;
    case 'DispositionNote':
      Comp = DispositionNoteIconCircled;
      break;
    case 'Voicemail':
      Comp = VoicemailIconCircled;
      break;
    case 'ChanSpy':
      Comp = ChanSpyIconCircled;
      break;
    case 'Emergency':
      Comp = EmergencyIcon;
      break;
    case ContactCustomerJourneyTypeEnum.Order:
      Comp = OrderIconCircled;
      break;
    case 'Unknown':
      Comp = UnknownIconCircled;
      break;
    }
  else if (direction)
    switch (direction) {
    case DirectionEnum.InBound:
      Comp = InboundIconCircled;
      break;
    case DirectionEnum.Outbound:
      Comp = OutboundIconCircled;
      break;
    case DirectionEnum.Internal:
      Comp = InternalIconCircled;
      break;
    }

  if (!padding)
    padding = (size / 2);

  // @ts-ignore
  if (!Comp)
    return <>n</>;

  return <Comp size={size} padding={padding} t={t} />;
});
