import { NavigateFunction, } from 'react-router';
import {
  FormElementTypes,
  FormLayoutTypes,
  IForm,
  IFormOptions,
} from 'atlas-form';
import { IAuth, IAbilities, } from '@Store';
import { CrudEnum, PermissionEnum, ISharedCreateSound, ISharedUpdateSound, ISharedSound, IPreviewOrganization, } from 'atlas-shared';
import { TFunction, } from 'i18next';
import { RestRequest, nestedArray, entitiesToOptions, onFormSaved, } from '@Utils';
import { downloadSoundFilename, streamSoundFilename, } from '@Api';

export interface ISoundFormOptionsProps {
  t: TFunction;
  is_new: boolean;
  abilities: IAbilities;
  organizations: Array<IPreviewOrganization>;
}

export const SoundFormOptions = (sound: ISharedCreateSound | ISharedSound, props: ISoundFormOptionsProps, t: TFunction): IFormOptions => ({
  organization_id: entitiesToOptions<IPreviewOrganization>(nestedArray<any>(props.organizations.filter(organization => props.abilities.can(organization.id, CrudEnum.Insert, PermissionEnum.Sound, {})), 'organization_id', false), 'organization'),
  
});

export const SoundComponents: IForm['components'] = {};
export const SoundAdditionalParams: (auth: IAuth, props: ISoundFormOptionsProps) => IForm['additionalParams'] = (auth: IAuth, props: ISoundFormOptionsProps) => ({
  filename: (it, b, c, d, sound: ISharedSound) => ({
    ui_type: FormElementTypes.FILE,
    nullable: false,
    label: 'FILENAME',
    uploadProps: {
      name: 'filename',
      accept: '.wav,.gsm,.mp3,.ogg',
      action: RestRequest.getFullSrc(`/sound/${sound.id}/filename`),
      headers: RestRequest.getHeaders(),
      onValueChange: (onChange) => {
        return (file) => {
          // if (file.file.status === 'done')
          //   onChange(file.file.name);
        };
      },
      defaultFileList: sound.original_filename ? [
        {
          uid: 'sound',
          name: sound.original_filename,
          status: 'done',
        },
      ] : [],
      showUploadList: {
        showRemoveIcon: false,
      },
      onDownload: file => {
        downloadSoundFilename(sound.id, file.name);
      },
      onPreview: () => streamSoundFilename(sound.id),
    },
  }),
});

export const SoundEditForm = (sound: ISharedSound, props: ISoundFormOptionsProps, t: TFunction, label?: string): IForm => ({
  id: `sound_edit_${sound.id}`,
  label: label,
  ui_layout: FormLayoutTypes.HORIZONTAL,
  elements: [
    { ui_type: FormElementTypes.PREVIEW, preview_value: props.organizations.find(it => it.id === sound.organization_id)?.title, path: 'organization_id', shouldUpdate: () => false, },
    { ref: 'title', },
    { ref: 'filename', },
    { ref: 'description', },
  ],
});

export const SoundAddForm = (sound: ISharedCreateSound, props: ISoundFormOptionsProps, t: TFunction, label?: string): IForm => ({
  id: 'sound_add',
  label: label,
  ui_layout: FormLayoutTypes.HORIZONTAL,
  elements: [
    { ref: 'organization_id', ui_type: FormElementTypes.TREE, },
    { ref: 'title', },
    { ref: 'description', },
  ],
});

export const SoundAddInitialValues: ISharedCreateSound = {} as ISharedCreateSound;

export const SoundEditInitialValues = (sound: ISharedSound): ISharedUpdateSound => ({
  title: sound.title,
  description: sound.description,
});

export const onSoundFormSaved = (t: TFunction, action: 'edit' | 'add', sound: ISharedSound, navigate: NavigateFunction): void => {
  navigate(onFormSaved('sounds'));
};
