import React, { useCallback, useMemo, } from 'react';

import { useTranslation, } from 'react-i18next';
import { useNavigate, } from 'react-router-dom';
import {
  StatsWidgetAddForm,
  StatsWidgetAddInitialValues,
  StatsWidgetComponents,
  StatsWidgetAdditionalParams,
  StatsWidgetFormOptions,
  onStatsWidgetFormSaved,
  IStatsWidgetFormOptionsProps,
  AlertError,
} from '@Utils';
import { AtlasForm, IFormOptions, } from 'atlas-form';
import { CrudEnum, ISharedCreateStatsWidget, PermissionEnum, } from 'atlas-shared';
import { createStatsWidget, getStatsWidgetCreateSchema, } from '@Api';
import { RuleProtected, } from '@Containers';
import { withAdd, IWithAddProps, } from '@Hocs/with.add';
import { useAbilities, useAuth, useOrganizations, useStatsCustomMetrics, useUsers, useQueues, useDispositions, useTeams, useMailAccounts, useVoiceRoutes, useAgentprofiles, } from '@Hooks';
import { AppSpinner, } from '@Components';
import { useAppDispatch, } from '@Store';

interface IProps extends IWithAddProps<ISharedCreateStatsWidget> {}

const StatsWidgetFormAdd = ({ values, setValues, save, formSchema, schemaLoading, saving, initialValues, title, onSaved, onCancel, onChange, hasAdvanced = false, }: IProps) => {
  const organizations = useOrganizations();
  const stats_custom_metrics = useStatsCustomMetrics();
  const users = useUsers();
  const queues = useQueues();
  const dispositions = useDispositions();
  const teams = useTeams();
  const mail_accounts = useMailAccounts();
  const voice_routes = useVoiceRoutes();
  const agentprofiles = useAgentprofiles();
  const abilities = useAbilities();
  const { t, } = useTranslation();
  const navigate = useNavigate();
  const auth = useAuth();
  const dispatch = useAppDispatch();

  const param_props: IStatsWidgetFormOptionsProps = {
    t,
    is_new: true,
    abilities,
    organizations: organizations.organizations,
    stats_custom_metrics: stats_custom_metrics.stats_custom_metrics,
    users: users.users,
    queues: queues.queues,
    dispositions: dispositions.dispositions,
    teams: teams.teams,
    mail_accounts: mail_accounts.mail_accounts,
    voice_routes: voice_routes.voice_routes,
    agentprofiles: agentprofiles.agentprofiles,
  };
  const formLayout = useMemo(() => StatsWidgetAddForm(values, param_props, t), [values, organizations, stats_custom_metrics, users, queues, dispositions, teams, mail_accounts, voice_routes, agentprofiles, t, abilities, ]);
  const options: IFormOptions = useMemo(() => StatsWidgetFormOptions(values || StatsWidgetAddInitialValues, param_props, t), [values, organizations, stats_custom_metrics, users, queues, dispositions, teams, mail_accounts, voice_routes, agentprofiles, t, abilities, ]);
  
  const onValuesChange = useCallback((changedValue: any, values: ISharedCreateStatsWidget, form) => {
    setValues(values);
    onChange?.(values, changedValue, form);
  }, [setValues, ]);
  const onFinish = useCallback((values: ISharedCreateStatsWidget) => save(() => createStatsWidget(values).then(
    res => onSaved ? onSaved(res, navigate) : onStatsWidgetFormSaved(t, 'add', res, navigate),
    err => {
      AlertError(t, {
        content: err,
      });
    }
  )), [save, navigate, t, ]);
  
  if (schemaLoading)
    return <AppSpinner />;

  if (!formSchema)
    return <>{t('SCHEMA_NOT_FOUND')}</>;
  
  if (!organizations.loaded || !stats_custom_metrics.loaded || !users.loaded || !queues.loaded || !dispositions.loaded || !teams.loaded || !mail_accounts.loaded || !voice_routes.loaded || !agentprofiles.loaded)
    return <>{t('LOADING_DEPENDENCIES')}</>;

  return (
    <RuleProtected
      isAllowed={() => !values || abilities.can(values.organization_id, CrudEnum.Insert, PermissionEnum.StatsWidget, values)}
    >
      <AtlasForm
        dispatch={dispatch}
        formLayout={formLayout}
        form={formSchema}
        options={options}
        components={StatsWidgetComponents}
        additionalParams={StatsWidgetAdditionalParams(auth, param_props)}
        onFinish={onFinish}
        onCancel={onCancel}
        onValuesChange={onValuesChange}
        initialValues={{ ...StatsWidgetAddInitialValues, ...initialValues, }}
        saving={saving}
        title={title ? t(title) : `${t('ADD')} ${t('STATS_WIDGET')}`}
        hasAdvanced={hasAdvanced}
      />
    </RuleProtected>
  );
};

export const StatsWidgetFormAddView = React.memo(withAdd(StatsWidgetFormAdd, getStatsWidgetCreateSchema, StatsWidgetAddInitialValues));
