import React, { useState, useEffect, useCallback, useMemo, } from 'react';
import { useTranslation, } from 'react-i18next';
import './conversation.reply.in.scss';
import { ClockIcon, } from '@Assets/icons';
import { ConversationStatusEnum, IPreviewConversation, Nullable, } from 'atlas-shared';
import { IconText, } from '@Components';

interface IProps {
  conversation: IPreviewConversation;
  short?: boolean;
  className?: string;
  icon?: boolean;
}

export const ConversationReplyIn = ({ conversation, short = false, className, icon, }: IProps) => {
  const { t, } = useTranslation();
  const [replyIn, setReplyIn, ] = useState<string>('');
  const [replyInPriority, setReplyInPriority, ] = useState<string>('priority-2');
  const [ago, setAgo, ] = useState<Nullable<string>>(null);
  const [dueDateDisplay, setDueDateDisplay, ] = useState<string>();
  const due_date = useMemo(() => conversation.response_time || (![ConversationStatusEnum.Closed, ConversationStatusEnum.Resolved, ].includes(conversation.status) && conversation.resolution_time), [conversation, ]);
  const [txt, txt2, ] = useMemo(() => (conversation.response_time ? [t('REPLY_IN'), t('REPLY_AT_LATEST'), ] : [t('RESOLVE_IN'), t('RESOLVE_AT_LATEST'), ]), [conversation, t, ]);
  const setReplyInText = useCallback(() => {

    if (!due_date)
      return <></>;

    const unit = (key: string) => `${!short ? ' ' : ''}${t(`${key}${short ? '_SHORT' : ''}`)}`;

    const now = new Date(),
      secondsPast = (new Date(due_date).getTime() - now.getTime()) / 1000,
      day = 24 * 60 * 60,
      hour = 60 * 60;
    let txt: string = '',
      priority: string = 'priority-1',
      postFix: Nullable<string> = secondsPast > 0 ? null : t('AGO');

    if (secondsPast > day) {
      txt = Math.round(secondsPast / day) + unit('DAYS');
    }
    else if (secondsPast < day * -1) {
      txt = Math.round(secondsPast / day * -1) + unit('DAYS');
      priority = 'priority-0';
    }
    else if (secondsPast > hour) {
      const hours = Math.round(secondsPast / hour);

      txt = hours + unit('HOURS');

      if (hours <= 3)
        priority = 'priority-2';
    }
    else if (secondsPast < hour * -1) {
      txt = Math.round(secondsPast / hour * -1) + unit('HOURS');
      priority = 'priority-0';
    }
    else if (secondsPast > 60) {
      txt = Math.round(secondsPast / 60) + unit('MINUTES');
      priority = 'priority-3';
    }
    else if (secondsPast < 0) {
      txt = Math.round(secondsPast / -60) + unit('MINUTES');
      priority = 'priority-0';
    }

    setReplyInPriority(priority);
    setReplyIn(txt);
    setAgo(postFix);
  }, [due_date, short, t, ]);

  useEffect(() => {

    if (!due_date)
      return;

    const d = new Date(due_date);

    setDueDateDisplay(`${txt2}: ${d.toLocaleDateString() !== new Date().toLocaleDateString() ? `${d.toLocaleDateString()} ` : ''}${d.toLocaleTimeString()}`);
    setReplyInText();
    const timer = setInterval(() => {
      setReplyInText();
    }, 60 * 1000);

    return () => {
      clearInterval(timer);
    };
  }, [due_date, txt2, setReplyInText, ]);

  if (!due_date)
    return <></>;

  return (
    <div className={`reply ${className || 'row center'} ${!replyIn && 'no-display'}`} title={dueDateDisplay}>
      {
        icon && <IconText className={'icon-wrapper'} icon={ClockIcon} iconProps={{ size: 12, }} iconWrapperStyle={{ marginRight: 3, }} textProps={{ style:{ fontSize: 10, color: 'var(--COLOR-000533)', }, }} text={short ? '' : txt}/>
      }
      <span className={`time-counter ${replyInPriority}`} >
        {replyIn} {!short && ago}
      </span>
    </div>
  );
};
