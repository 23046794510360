import { NavigateFunction, } from 'react-router';
import {
  FormElementTypes,
  FormLayoutTypes,
  IForm,
  IFormOptions,
} from 'atlas-form';
import { IAuth, IAbilities, } from '@Store';
import { CrudEnum, PermissionEnum, ISharedCreateOperationalHours, ISharedUpdateOperationalHours, ISharedOperationalHours, IPreviewOrganization, IPreviewTimeInterval, } from 'atlas-shared';
import { TFunction, } from 'i18next';
import { nestedArray, entitiesToOptions, filterRelated, onFormSaved, } from '@Utils';

export interface IOperationalHoursFormOptionsProps {
  t: TFunction;
  is_new: boolean;
  abilities: IAbilities;
  organizations: Array<IPreviewOrganization>;
  time_intervals: Array<IPreviewTimeInterval>;
}

export const OperationalHoursFormOptions = (operational_hours: ISharedCreateOperationalHours | ISharedOperationalHours, props: IOperationalHoursFormOptionsProps, t: TFunction): IFormOptions => ({
  organization_id: entitiesToOptions<IPreviewOrganization>(nestedArray<any>(props.organizations.filter(organization => props.abilities.can(organization.id, CrudEnum.Insert, PermissionEnum.OperationalHours, {})), 'organization_id', false), 'organization'),
  __time_intervals: (it: ISharedOperationalHours) => filterRelated<IPreviewTimeInterval>(it.organization_id || operational_hours.organization_id, 'time_interval', 'operational_hours', props.time_intervals, true),
  
});

export const OperationalHoursComponents: IForm['components'] = {};
export const OperationalHoursAdditionalParams: (auth: IAuth, props: IOperationalHoursFormOptionsProps) => IForm['additionalParams'] = (auth: IAuth, props: IOperationalHoursFormOptionsProps) => ({
  __time_intervals: {
    onModalAdd: {
      key: 'queue-__time_intervals-add',
      path: '@Components/page-views/time_interval',
      component: 'TimeIntervalFormAddView',
      item_text: 'TIME_INTERVAL',
    },
  },
});

export const OperationalHoursEditForm = (operational_hours: ISharedOperationalHours, props: IOperationalHoursFormOptionsProps, t: TFunction, label?: string): IForm => ({
  id: `operational_hours_edit_${operational_hours.id}`,
  label: label,
  ui_layout: FormLayoutTypes.HORIZONTAL,
  elements: [
    { ui_type: FormElementTypes.PREVIEW, preview_value: props.organizations.find(it => it.id === operational_hours.organization_id)?.title, path: 'organization_id', shouldUpdate: () => false, },
    { ref: 'title', },
    { ref: 'timezone', },
    { ref: '__time_intervals', },
  ],
});

export const OperationalHoursAddForm = (operational_hours: ISharedCreateOperationalHours, props: IOperationalHoursFormOptionsProps, t: TFunction, label?: string): IForm => ({
  id: 'operational_hours_add',
  label: label,
  ui_layout: FormLayoutTypes.HORIZONTAL,
  elements: [
    { ref: 'organization_id', ui_type: FormElementTypes.TREE, },
    { ref: 'title', },
    { ref: 'timezone', },
    { ref: '__time_intervals', },
  ],
});

export const OperationalHoursAddInitialValues: ISharedCreateOperationalHours = {} as ISharedCreateOperationalHours;

export const OperationalHoursEditInitialValues = (operational_hours: ISharedOperationalHours): ISharedUpdateOperationalHours => ({
  title: operational_hours.title,
  timezone: operational_hours.timezone,
  __time_intervals: operational_hours.__time_intervals,
});

export const onOperationalHoursFormSaved = (t: TFunction, action: 'edit' | 'add', operational_hours: ISharedOperationalHours, navigate: NavigateFunction): void => {
  navigate(onFormSaved('operational_hourses'));
};
