import React, { useCallback, useEffect, useMemo, } from 'react';
import { useTranslation, } from 'react-i18next';
import { useNavigate, } from 'react-router-dom';
import { AtlasForm, IForm, IFormOptions, } from 'atlas-form';
import {
  StatsWidgetEditForm,
  StatsWidgetEditInitialValues,
  StatsWidgetComponents,
  StatsWidgetAdditionalParams,
  StatsWidgetFormOptions,
  onStatsWidgetFormSaved,
  IStatsWidgetFormOptionsProps,
  AlertError,
} from '@Utils';
import { ISharedStatsWidget, ISharedPatchStatsWidget, PermissionEnum, CrudEnum, } from 'atlas-shared';
import { fetchStatsWidget, patchStatsWidget, getStatsWidgetUpdateSchema, } from '@Api';
import { RuleProtected, } from '@Containers';
import { withEdit, IWithEditProps, } from '@Hocs/with.edit';
import { useAbilities, useAuth, useOrganizations, useStatsCustomMetrics, useUsers, useQueues, useDispositions, useTeams, useMailAccounts, useVoiceRoutes, useAgentprofiles, } from '@Hooks';
import { AppSpinner, } from '@Components';
import { useAppDispatch, } from '@Store';

interface IProps extends IWithEditProps<ISharedStatsWidget> {}

const StatsWidgetFormEdit = ({ id, value, setValue, formSchema, schemaLoading, saving, save, title, onSaved, onCancel, onChange, hasAdvanced = false, }: IProps) => {
  const { t, } = useTranslation();
  const navigate = useNavigate();
  const auth = useAuth();
  const abilities = useAbilities();
  const dispatch = useAppDispatch();
  const organizations = useOrganizations();
  const stats_custom_metrics = useStatsCustomMetrics();
  const users = useUsers();
  const queues = useQueues();
  const dispositions = useDispositions();
  const teams = useTeams();
  const mail_accounts = useMailAccounts();
  const voice_routes = useVoiceRoutes();
  const agentprofiles = useAgentprofiles();

  useEffect(() => {
    fetchStatsWidget(id).then(stats_widget => setValue(stats_widget));
  }, [id, setValue, ]);

  const param_props: IStatsWidgetFormOptionsProps = {
    t,
    is_new: false,
    abilities,
    organizations: organizations.organizations,
    stats_custom_metrics: stats_custom_metrics.stats_custom_metrics,
    users: users.users,
    queues: queues.queues,
    dispositions: dispositions.dispositions,
    teams: teams.teams,
    mail_accounts: mail_accounts.mail_accounts,
    voice_routes: voice_routes.voice_routes,
    agentprofiles: agentprofiles.agentprofiles,
  };
  const formLayout: IForm | undefined = useMemo(() => value && StatsWidgetEditForm(value, param_props, t), [value, organizations, stats_custom_metrics, users, queues, dispositions, teams, mail_accounts, voice_routes, agentprofiles, t, abilities, ]);
  const options: IFormOptions | undefined = useMemo(() => value && StatsWidgetFormOptions(value, param_props, t), [value, organizations, stats_custom_metrics, users, queues, dispositions, teams, mail_accounts, voice_routes, agentprofiles, t, abilities, ]);
  
  const onValuesChange = useCallback((changedValue: any, values: ISharedPatchStatsWidget, form) => {
    onChange?.(values, changedValue, form);
  }, []);
  const onFinish = useCallback((values: ISharedPatchStatsWidget) => value && save(() => patchStatsWidget(value.id, values).then(
    res => onSaved ? onSaved(res, navigate) : onStatsWidgetFormSaved(t, 'edit', res, navigate),
    err => {
      AlertError(t, {
        content: err,
      });
    }
  )), [value, save, navigate, t, ]);
  
  if (schemaLoading)
    return <AppSpinner />;

  if (!value)
    return <>{t('ITEM_NOT_FOUND')}</>;

  if (!formSchema)
    return <>{t('SCHEMA_NOT_FOUND')}</>;
  
  if (!organizations.loaded || !stats_custom_metrics.loaded || !users.loaded || !queues.loaded || !dispositions.loaded || !teams.loaded || !mail_accounts.loaded || !voice_routes.loaded || !agentprofiles.loaded)
    return <>{t('LOADING_DEPENDENCIES')}</>;

  return (
    <RuleProtected
      isAllowed={() => !value || abilities.can(value.organization_id, CrudEnum.Update, PermissionEnum.StatsWidget, value)}
    >
      <AtlasForm<ISharedPatchStatsWidget>
        dispatch={dispatch}
        formLayout={formLayout as IForm}
        form={formSchema}
        options={options}
        components={StatsWidgetComponents}
        additionalParams={StatsWidgetAdditionalParams(auth, param_props)}
        onFinish={onFinish}
        onCancel={onCancel}
        onValuesChange={onValuesChange}
        initialValues={StatsWidgetEditInitialValues(value)}
        saving={saving}
        title={title ? t(title) : `${t('EDIT')} ${t('STATS_WIDGET')}`}
        entityId={value.id}
        entity={value}
        hasAdvanced={hasAdvanced}
      />
    </RuleProtected>
  );
};

export const StatsWidgetFormEditView = React.memo(withEdit(StatsWidgetFormEdit, getStatsWidgetUpdateSchema));
